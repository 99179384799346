import React, { memo, useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useEditGame, useGameEditMechanic } from 'src/hooks';
import { TCrosswordGame, ICrosswordEditorClue, IGridObj, TCrosswordGameRow } from './types';
import GameNotFound from '../../../not-found';
import { TGameMechanicContent } from 'src/@types';
import CluePopup from './clue-popup';
import { motion } from 'framer-motion';
import 'react-tooltip/dist/react-tooltip.css';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //


interface IOpenCell {
  index: number;
  cell: string
}

export type filledObj = Record<string, ICrosswordEditorClue>

const defaultGrid = { row: 8, column: 8 };
const gridLimits = { row: 200, column: 200 };

/**
 * @description
 *    The calculation game component
 * @constructor
 */
function CrosswordGameEditor() {
  const [filled, setFilled] = useState<Record<string, filledObj>>({});
  const [activeCell, setActiveCell] = useState(0);
  const inputsRef = useRef<HTMLInputElement[] | null[]>([]);

  const [openCell, setOpenCell] = useState<IOpenCell | null>(null);
  /**
   * @description
   *    The game mechanic hooks that are necessary to load data and manage
   *    processes.
   */
  const {
    gameMechanic,
    updateGameMechanic
  } = useGameEditMechanic();

  const { showTheHintsPopup } = useEditGame();

  const validateCurrentContent = () => {
    // ToDo add the validation
    return true;
  };

  if (gameMechanic) {
    const { content } = gameMechanic;
    const { across, down, grid = { ...defaultGrid } } = content as TCrosswordGame;
    const gridCount = useMemo(() => grid.row * grid.column, [grid.row, grid.column]);
    const onGameMechanicContentChange = (finalContent: TCrosswordGame) => {
      if (validateCurrentContent()) {
        updateGameMechanic({
          ...gameMechanic,
          content: finalContent as unknown as TGameMechanicContent
        });
      }
    };

    const addGridCells = (key: keyof IGridObj) => {
      if (grid[key] < gridLimits[key]) {
        onGameMechanicContentChange({
          ...content as TCrosswordGame,
          grid: { ...grid, [key]: grid[key] + 1 }
        });
      } else {
        return;
      }
    };

    const removeGridCells = (key: keyof IGridObj) => {
      if (grid[key] > defaultGrid[key]) {
        onGameMechanicContentChange({
          ...content as TCrosswordGame,
          grid: { ...grid, [key]: grid[key] - 1 }
        });
      } else {
        return;
      }
    };

    const cluesCount = useMemo(()=> Object.keys({ ...across, ...down }).length+1,[across,down]);


    const saveData = (data: ICrosswordEditorClue) => {
      if (openCell) {
        const oppositeDirection = data.direction === "across" ? 'down' : 'across'
        const row = Math.floor(activeCell / grid.column);
        const col = activeCell % grid.column;
        const oppositeData = (content as TCrosswordGame)[oppositeDirection]
        const existsInData = Object.keys(oppositeData).find(key=> oppositeData[key].row === row && oppositeData[key].col === col)
        const cellName = existsInData ? existsInData : cluesCount
        let finalContent: TCrosswordGame;
        if (!data.clue && !data.answer) {
          const finalContentDirection = { ...(content as TCrosswordGame)[data.direction] } as Record<string, TCrosswordGameRow>;
          delete finalContentDirection[cellName];
          finalContent = {
            ...(content as TCrosswordGame),
            [data.direction]: finalContentDirection
          };
          setFilled(prev => {
            let clone = { ...prev };
            delete clone[openCell.index];
            return clone;
          });
        } else {
          finalContent = {
            ...(content as TCrosswordGame),
            [data.direction]: {
              ...(content as TCrosswordGame)?.[data.direction],
              [cellName]: {
                ...(content as TCrosswordGame)?.[data.direction]?.[`${cellName}`],
                // clue: `${cellName} - ${data.clue}`,
                clue: data.clue,
                answer: data.answer?.toUpperCase() || '',
                col,
                row,
                order:cellName
              }
            }
          };
        }
        onGameMechanicContentChange(finalContent);
        setOpenCell(null);
      }
    };

    const openCellInfo = (index: number) => {
      const row = Math.floor(index / grid.column);
      const column = index % grid.column;
      setActiveCell(index);
      setOpenCell({ cell: `${getLetterByIndex(column)} - ${row + 1}`, index });
    };


    useEffect(() => {
      inputsRef.current?.[activeCell]?.focus();
    }, [activeCell]);

    const getLetterByIndex = (index: number): string => {
      const alphabet = 'abcdefghijklmnopqrstuvwxyz';
      const length = alphabet.length;

      if (index < length) {
        return alphabet[index];
      } else {
        let prefix = alphabet[Math.floor(index / length) - 1];
        let suffix = alphabet[index % length];
        return prefix + suffix;
      }
    };

    const fillAcross = () => {
      Object.entries(across).map(([key, el]) => {
        let { answer, row, col, clue, order, letterIndex } = el;
        let letterArr = answer.split('');
        let newObj = {};
        letterArr.map((letter, i) => {
          let letterObj = { letter, answer, row, col: col + i, clue, direction: 'across', order, letterIndex: i };
          let index = row * grid.column + col + i;
          let downObj = { letter, answer: '', row, col: col + i, clue: '', direction: 'down', order, letterIndex: i };
          if (filled[index]?.down) {
            // @ts-ignore
            downObj = filled[index].down;
          }
          if (down?.[key]?.row === row && down?.[key]?.col === col + i) {
            downObj = {
              letter: '',
              answer: down[key].answer,
              row,
              col: col + i,
              clue: down[key].clue,
              direction: 'down',
              order,
              letterIndex: i,
            };
          };
          newObj = { ...newObj, [index]: { across: letterObj, down: downObj } };
        });
        setFilled(newObj);
      });

    };

    const getRowAndColumn = (index: number, numColumns: number) => {
      const row = Math.floor(index / numColumns);
      const column = index % numColumns;
      return { row, column };
    }

    const fillDown = () => {
      let newObj = {};
      Object.entries(down).map(([key, el]) => {
        let { answer, row, col, clue, order, letterIndex } = el;
        let letterArr = answer.split('');
        letterArr.map((letter, i) => {
          let letterObj = { letter, answer, row: row + i, col, clue, direction: 'down', order, letterIndex: i };
          let index = (row + i) * grid.column + col;
          let acrossObj = { letter, answer: '', row: row + i, col, clue: '', direction: 'across', order, letterIndex: i };

          if (across?.[key]?.row === row + i && across?.[key]?.col === col) {
            acrossObj = {
              letter: '',
              answer: across[key].answer,
              row: row + i,
              col,
              clue: across[key].clue,
              direction: 'across',
              order,
              letterIndex: i,
            };
          }
          newObj = { ...newObj, [index]: { down: letterObj, across: acrossObj } };
        });
      });
      setFilled(newObj);
    };


    useEffect(() => {
      fillDown();
      fillAcross();
    }, [down, across, gridCount]);

    let clueData = {};

    if (openCell && openCell.index >= 0) {
      const selectedClueCell = filled[openCell.index];
      if (selectedClueCell && selectedClueCell?.across?.letterIndex !== undefined && `${selectedClueCell?.across?.letterIndex}` === '0') {
        clueData = { across: { ...selectedClueCell?.across } };
      }

      if (selectedClueCell && selectedClueCell?.down?.letterIndex !== undefined && `${selectedClueCell?.down?.letterIndex}` === '0') {
        clueData = { ...clueData, down: { ...selectedClueCell?.down } };
      }
      // if (selectedClueCell) {
      //   const {row, column} = getRowAndColumn(openCell.index, grid.column);
      //   // if (row === selectedClueCell?.down?.row)
      // }
    }

    return (
      <>
        <div className='crossword-editor'>
          {openCell && <CluePopup cell={cluesCount} saveData={saveData} onClose={() => setOpenCell(null)}
                                  data={Object.keys(clueData)?.length > 0 ? clueData : undefined} clickedIndex={openCell.index} width={grid?.column} />}
          <Container fluid>
            <Row>
              <Col lg={9} className='horizontal-scroll'>
                <div
                  className='column-counts'
                  style={{ gridTemplateColumns: `repeat(${grid.column}, 3rem)` }}
                >
                  {
                    new Array(grid.column).fill('').map((r, i) =>
                      <h3 key={`column-${i}`}>{getLetterByIndex(i)}</h3>)
                  }
                </div>

                <div className='d-flex'>
                  <div
                    className='row-counts'
                    style={{ gridTemplateRows: `repeat(${grid.row}, 3rem)` }}
                  >
                    {
                      new Array(grid.row).fill('').map((r, i) =>
                        <h3 key={`row-${i}`}>{i + 1}</h3>)
                    }
                  </div>
                  <div
                    className='crossword-grid'
                    style={{ gridTemplateColumns: `repeat(${grid.column}, 3rem)` }}
                  >
                    {
                      new Array(gridCount).fill('').map((grid, i) => (
                        <div key={`grid-${i}`}>
                          {/*<ReactTooltip id="double-click-tip" >*/}
                          {/*  Tooltip for the register button*/}
                          {/*</ReactTooltip>*/}
                          <input
                            ref={(el) => inputsRef.current[i] = el}
                            maxLength={1}
                            onDoubleClick={() => openCellInfo(i)}
                            value={filled?.[i]?.['across']?.letter || filled?.[i]?.['down']?.letter || ''}
                            data-tooltip-id='double-click-tip'
                            data-tooltip-content='Double click on cell for clue'
                            readOnly={true}
                          />
                        </div>))
                    }
                  </div>
                </div>
              </Col>
              <Col lg={3}>
                <div className='configs-wrapper'>
                  <div className='crossword-configs'>
                    <div>
                      <div className='remove-btn'>
                        <motion.button whileTap={{ scale: 0.97 }}
                                       onClick={() => removeGridCells('row')}>-
                        </motion.button>
                      </div>
                      <label>Row</label>
                      <div className='add-btn'>
                        <motion.button whileTap={{ scale: 0.97 }}
                                       onClick={() => addGridCells('row')}>+
                        </motion.button>
                      </div>
                    </div>
                    <div>
                      <div className='remove-btn'>
                        <motion.button whileTap={{ scale: 0.97 }}
                                       onClick={() => removeGridCells('column')}>-
                        </motion.button>
                      </div>
                      <label>Column</label>
                      <div className='add-btn'>
                        <motion.button whileTap={{ scale: 0.97 }}
                                       onClick={() => addGridCells('column')}>+
                        </motion.button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {(Object.keys(across).length || Object.keys(down).length) ?
          <div className='clues-info'>
            <Container>
              <Row>
                <Col><h3>Across:</h3>
                  <ol>
                    {Object.values(across).map((acrossValue, index) => <li
                      key={`across_${index}`}>{acrossValue.clue}</li>)}
                  </ol>
                </Col>
                <Col><h3>Down:</h3>
                  <ol>
                    {Object.values(down).map((downValue, index) => <li key={`down_${index}`}>{downValue.clue}</li>)}
                  </ol>
                </Col>
              </Row>
            </Container>

          </div> : null
        }
      </>
    );
  }

  return <GameNotFound />;
}

export default memo(CrosswordGameEditor);
