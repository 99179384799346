import { Col, Row } from 'react-bootstrap';
import React, { memo } from 'react';
import { useAuth, useEditGame } from "src/hooks";
import { CLASSTER_TENANTS_BY_UUID, PROGRAM_NAMES_BY_UUID } from '../../@constants/tenants-constants';
import { Timestamp } from "firebase/firestore";
import { EnumGameLevelNumbers, EnumGameSkillsCategory } from '../../@constants';

function SelectedGameConfigs(){
  const { game, submitUpdatedGame } = useEditGame();
  const { user, isSuperAdmin, tenant } = useAuth();

  const updateGameType = (gameType: string) => {
    if (game && (gameType === 'core' || gameType === 'mini')) {
      submitUpdatedGame({
        ...game,
        type: gameType,
      });
    }
  }

  const updateGameSkill = (mini_world_id: string) => {
    if (game && mini_world_id && mini_world_id !== '') {
      submitUpdatedGame({
        ...game,
        mini_world_id,
      });
    }
  }

  const updateGameInstitute = (tenant_id: string) => {
    if (game && tenant_id && tenant_id !== '' && isSuperAdmin) {
      submitUpdatedGame({
        ...game,
        tenant_id
      });
    }
  }

  const updateGameProgramName = (program_name: string) => {
    if (game && program_name && program_name !== '' && isSuperAdmin) {
      submitUpdatedGame({
        ...game,
        program_name
      });
    }
  }

  const updateGameProgram= (program_name: string) => {
    if (game && program_name && program_name !== '' && isSuperAdmin) {
      submitUpdatedGame({
        ...game,
        program_name
      });
    }
  }

  /**
   * @description
   *  The students cannot see the metadata section
   */
  if (user?.role?.toLowerCase() === 'student' || !user?.role) {
    return null;
  }

  return (
    <div className='configs'>
      <Row>
        <Col md={5}>
          <div className='config-item'>
            <p>Created by: {game?.created_by}</p>
          </div>
          <div className='config-item'>
            <p>Created at: {(game as unknown as Record<string, Timestamp>)?.created_at?.toDate()?.toDateString()}</p>
          </div>
          <div className='config-item'>
            <p>Last Edited: {(game as unknown as Record<string, Timestamp>)?.updated_at?.toDate()?.toDateString()}</p>
          </div>
          <div className='config-item'>
            <p>Last Edited by: {game?.updated_by}</p>
          </div>
          <div className='config-item'>
            <p>Publication Date:</p>
          </div>
          <div className='config-item'>
            <p>Version: 1.0</p>
          </div>
        </Col>
        <Col md={3}>
          <div className='config-item mb-2'>
            <select className="form-control" defaultValue={game?.status === 'published' ? 'published' : 'edit'}>
              <option value={'published'}>Published</option>
              <option value={'edit'}>Edited</option>
            </select>
          </div>
          <div className='config-item mb-2'>
            <select className="form-control" defaultValue={game?.mini_world_id}
                    onChange={(e) => updateGameSkill(e.target.value)}>
              {(Object.keys(EnumGameSkillsCategory) as Array<keyof typeof EnumGameSkillsCategory>)
                .map((skill, i) => <option key={`skill-${skill}`} value={i}>{skill}</option>)}
            </select>
          </div>
          <div className='config-item mb-2'>
            <select className="form-control" defaultValue={game?.type} onChange={(e) => updateGameType(e.target.value)}>
              <option value={'core'}>Core</option>
              <option value={'mini'}>Mini</option>
            </select>
          </div>
          <div className='config-item mb-2'>
            <select className="form-control" defaultValue={game?.level}
                    onChange={(e) => updateGameType(e.target.value)}>
              {Object.keys(EnumGameLevelNumbers).filter((level) => !isNaN(Number(level)))
                .map(level => <option key={`skill-${level}`} value={level}>{level}</option>)}
            </select>
          </div>
          <div className='config-item mb-2'>
            <select
              className="form-control"
              defaultValue={game?.tenant_id ?? undefined}
              onChange={(e) => updateGameInstitute(e.target.value)}
            >
              <option key={'none'} value={''}>Institute</option>
              {Object
                .entries(CLASSTER_TENANTS_BY_UUID)
                .map(([tenantId, tenantName]) =>
                  <option value={tenantId} key={tenantId}>{tenantName}</option>)
              }
            </select>
          </div>
          <div>
            <select
              className="form-control"
              defaultValue={game?.program_name ?? undefined}
              onChange={(e) => updateGameProgramName(e.target.value)}
            >
              <option key={'none'} value={''}>Program</option>
              {Object
                .entries(PROGRAM_NAMES_BY_UUID)
                .map(([instituteId, programmItems]) => {
                  if (game?.tenant_id && instituteId === game.tenant_id) {
                    return Object.values(programmItems).map((programName) =>
                      <option
                        value={programName}
                        key={programName}>
                        {programName}
                      </option>
                    )
                  }
                })
              }
            </select>
          </div>
          {/*<div className='config-item mb-2'>*/}
          {/*  <input*/}
          {/*    className="form-control"*/}
          {/*    placeholder="Fill in the program"*/}
          {/*    onChange={(e) => updateGameProgram(e.target.value)}*/}
          {/*  />*/}
          {/*</div>*/}
        </Col>
        <Col md={4}>
          <div className='config-item mb-2'>
            <span className="me-md-2  text-nowrap">
              Publication time period:
            </span>
            <div className="d-md-flex d-block">
              <input
                placeholder="insert start date"
                className="me-md-2 mb-3 mb-md-0 form-control"
              />
              <input className="form-control" placeholder="insert start date" />
            </div>
          </div>
          <div>
            <span>Comments</span>
            <textarea className="form-control" rows={6} />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default memo(SelectedGameConfigs);