import { Container } from 'react-bootstrap';
import 'src/assets/styles/game-overview.scss';
import DeleteBtn from '../../assets/svg/buttons/delete-btn';
import EditBtn from '../../assets/svg/buttons/edit-btn';
import FilterBtn from '../../assets/svg/buttons/filter-btn';
import PublishedIcon from '../../assets/svg/icons/published-icon';
import ReviewIcon from '../../assets/svg/icons/review-icon';
import ArrowIcon from '../../assets/svg/icons/arrow-icon';
import { ButtonMotion } from '../../components';
import { useEffect, useState } from 'react';
import Filter from './filter';
import CloseBtn from '../../assets/svg/buttons/close-btn';
import { filterFunctionsArr } from './filter-functions';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getAllGames,
  updateGameService,
  updateGameStatus as updateGameStatusService
} from '../../api/v0';
import { PATH_GLMS_PLAYER } from '../../routes/paths';
import Form from 'react-bootstrap/Form';
import { EnumGamePublicationStatus, EnumGameStatus } from '../../@constants';
import { useAuth } from '../../hooks';

export interface FilterData {
  id?: string,
  created_at?: string,
  updated_at?: string
  status?: string,
  title?: string,
  created_by?: string,
  key?: string,
  skill?: string,
}

export default function GamesOverview() {
  const [filter, setFilter] = useState<string>('');
  const [filterData, setFilterData] = useState<FilterData>({});
  const [data, setData] = useState<Record<string, any>[]>([]);
  const [filteredData, setFilteredData] = useState<FilterData[]>(data);
  const [sortingData, setSortingData] = useState<Record<string, string | null>>({
    name: null,
    created_by: null,
    updated: null,
    key: null,
  });

  const navigateTo = useNavigate();
  const { user } = useAuth();

  const role = user?.role?.toLowerCase();

  useEffect(() => {
    setFilteredData(filterFunctionsArr.reduce((d, f) =>
      d.filter((row) => f(row, filterData)), data))
  }, [filterData, data]);

  useEffect(() => {
    getAllGames((allGames) => setData(allGames.map((game) => ({
      title: game.title,
      status: game?.status ?? 'new',
      created_at: game.created_at.toDate().toDateString(),
      updated_at: game.updated_at.toDate().toDateString(),
      id: game.id,
      created_by: game.created_by,
      skill: game.skill
    }))), sortingData);
  }, [sortingData]);

  function deleteRow(rowId?: string) {
    // setFilteredData(prev=> prev.filter((row,i)=> index !== i))
    //TODO delete from main data too
    if (rowId) {
      updateGameService(
        rowId,
        { active: false },
        () => getAllGames((allGames) => setData(allGames.map((game) => ({
          title: game.title,
          status: game?.status ?? 'new',
          created_at: game.created_at.toDate().toDateString(),
          updated_at: game.updated_at.toDate().toDateString(),
          id: game.id,
          created_by: game.created_by,
        }))))
      )
    }
  }

  function updateGameStatus(newRow: FilterData) {
    setFilteredData(
      filteredData.map((filter) => filter.id === newRow.id ? newRow : filter)
    );

    if (newRow.status !== '') {
      updateGameStatusService(
        newRow.id ?? 'none',
        newRow.status as EnumGamePublicationStatus,
        () => {},
        (error) => console.log(error));
    }
  }

  function getStatusView(row: FilterData) {
    return (
      <Form.Select
        aria-label='Default select example'
        value={row.status || 'new'}
        onChange={(e) => updateGameStatus({...row, status: e.target.value})}
        style={{ backgroundColor: '#67A74C' }}
      >
        <option value=''>Open this select menu</option>
        <option value={EnumGamePublicationStatus.created}><ReviewIcon /> New</option>
        <option value={EnumGamePublicationStatus.reviewed}><ReviewIcon /> Reviewed</option>
        <option value='edit'><ReviewIcon /> Need Edit</option>
        <option value='published'><PublishedIcon /> Publish</option>
      </Form.Select>
    );
  }

  function filterByStatus(status: 'published' | 'reviewed') {

    setFilterData(prev => {
      if(prev.status === status){
        return ({...prev, status:''})
      }
      return ({ ...prev, status })
    });
  }

  if (role !== 'admin' && role !== 'mentor' && role !== 'teacher' && role !== 'lecturer' && role !== 'administrator') {
    return <p>Permission Denied</p>;
  }

  return (
    <Container fluid={'xxl'}>
      <div className='games-overview'>
        {filter &&
          <Filter filter={filter} save={(data) => setFilterData(data)} fData={filterData} close={() => setFilter('')} />}
        <div className='game-wrapper animate'>
          <div className='game-container editor game-picker'>
            <CloseBtn close={() => { navigateTo('/') }} />
            <h2>Games Publication Status</h2>
            <table className='table'>
              <thead className='thead-light text-uppercase'>
              <tr>
                <th scope='col'>
                  <div>Title</div>
                </th>
                <th scope='col'>
                  <div>Skill</div>
                </th>
                <th scope='col'>
                  <div>Created By</div>
                </th>
                {/*<th scope='col'>*/}
                {/*  <div>Creation date</div>*/}
                {/*</th>*/}
                <th scope='col'>
                  <div>Last update</div>
                </th>
                <th scope='col'>
                  <div>status</div>
                </th>
                <th scope='col'></th>
              </tr>
              <tr>
                <th scope='col'>
                  <div><FilterBtn onClick={() => setFilter('title')}/>
                    <div className='filter-btn' onClick={() => setSortingData({
                      name: sortingData?.name !== 'desc' ? 'desc' : 'asc',
                      created_by: null,
                      updated: null,
                      key: 'title',
                      value: sortingData?.name !== 'desc' ? 'desc' : 'asc',
                    })}>
                      <ArrowIcon
                        direction={sortingData?.name === 'desc' ? 'down' : 'up'}/>
                      <span>A-Z</span></div>
                  </div>
                </th>
                <th scope='col'>
                  <div><FilterBtn onClick={() => setFilter('skill')}/>
                    {/*<div className='filter-btn' onClick={() => setSortingData({*/}
                    {/*  skill: sortingData?.skill !== 'desc' ? 'desc' : 'asc',*/}
                    {/*  created_by: null,*/}
                    {/*  updated: null,*/}
                    {/*  key: 'skill',*/}
                    {/*  value: sortingData?.skill !== 'desc' ? 'desc' : 'asc',*/}
                    {/*})}>*/}
                    {/*  <ArrowIcon*/}
                    {/*    direction={sortingData?.skill === 'desc' ? 'down' : 'up'}/>*/}
                    {/*  <span>A-Z</span></div>*/}
                  </div>
                </th>
                <th scope='col'>
                  <div><FilterBtn onClick={() => setFilter('created_by')}/>
                    <div className='filter-btn' onClick={() => setSortingData({
                      created_by: sortingData?.created_by !== 'desc' ? 'desc' : 'asc',
                      name: null,
                      updated: null,
                      key: 'created_by',
                      value: sortingData?.created_by !== 'desc' ? 'desc' : 'asc',
                    })}><ArrowIcon
                      direction={sortingData?.created_by === 'desc' ? 'down' : 'up'}/>
                      <span>A-Z</span></div>
                  </div>
                </th>
                {/*<th scope='col'>*/}
                {/*  <div><FilterBtn onClick={() => setFilter('date')} />*/}
                {/*    <div><ArrowIcon direction='down' /> <span>ASC</span></div>*/}
                {/*  </div>*/}
                {/*</th>*/}
                <th scope='col'>
                  <div><FilterBtn onClick={() => setFilter('updated')}/>
                    <div onClick={() => setSortingData({
                      updated: sortingData?.updated !== 'desc' ? 'desc' : 'asc',
                      name: null,
                      created_by: null,
                      key: 'updated_at',
                      value: sortingData?.updated !== 'desc' ? 'desc' : 'asc',
                    })} className='filter-btn'><ArrowIcon
                      direction={sortingData?.updated === 'desc' ? 'down' : 'up'}/>
                      <span>DESC</span></div>
                  </div>
                </th>
                <th scope='col'>
                  <div><FilterBtn onClick={() => setFilter('status')}/>
                    <div>
                      <div className='filter-btn'
                           onClick={() => filterByStatus('published')}>
                        <PublishedIcon/>
                        <span>Pub</span></div>
                      <div className='filter-btn'
                           onClick={() => filterByStatus('reviewed')}>
                        <ReviewIcon/>
                        <span>Rev</span></div>
                    </div>
                  </div>
                </th>
                <th scope='col'></th>
              </tr>
              </thead>
              <tbody>
              {filteredData.map((row, i) => {
                if (role !== 'administrator' && row.created_by?.toLowerCase() !== user?.email?.toLowerCase()) {
                  return null;
                }
                return (
                  <tr key={`row-${row.id}`}>
                    <td>
                      <div>{row.title}</div>
                    </td>
                    <td>
                      <div>{row.skill}</div>
                    </td>
                    <td>
                      <div>{row.created_by}</div>
                    </td>
                    <td>
                      <div>{row.updated_at}</div>
                    </td>
                    <td>
                      {getStatusView(row)}
                    </td>
                    <td>
                      <EditBtn onClick={() => {
                        navigateTo(`/${PATH_GLMS_PLAYER.games.game.get(row.id || "0")}`);
                      }}/>
                      <DeleteBtn onClick={() => deleteRow(row.id)}/>
                    </td>
                  </tr>);
              })}
              </tbody>
            </table>
          </div>
          <div className='submit-btn'>
            <ButtonMotion
              className='btn-block'
              variant={'success'}
              onClick={() => {
              }}
            >
              Next
            </ButtonMotion>
          </div>
        </div>
      </div>
    </Container>
  );
}