import { useWorld } from 'src/hooks';
import { Container } from 'react-bootstrap';
import { TMiniWorld } from 'src/@types';
import { getIslandComponent } from './assets/icons';
import React, { useEffect } from 'react';
import { checkIsMobile } from '../../helpers';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';

// import required modules
import { EffectCoverflow, Pagination } from 'swiper/modules';
// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description - The transition variable for the motion div component setup
 */


/**
 * @description - The Islands world that contains all necessary games
 * @constructor
 */
export default function WorldIslands() {
  const {
    world,
    miniWorld,
    miniWorldGames,
    selectIsland,
    closeMiniWorld
  } = useWorld();

  const isMobile = checkIsMobile();

  /**
   * @description - The method is generating the individual mini world component
   * @param { TMiniWorld } island - The mini world object's data
   */
  const getIsland = (island: TMiniWorld) => {
    return (
      <g
        key={island.title} className={`skill-island ${miniWorld?.id === island.id ? 'opening' : ''}`}
        onClick={() => selectIsland('demoClassId', 'demoCourseId', island.id)}>
        {getIslandComponent(island.icon)}
      </g>
    );
  };

  useEffect(() => {
    if (miniWorld) {
      closeMiniWorld();
    }
  }, []);

  return (
    <Container className='islands animate d-flex align-items-center'>
      {isMobile ?
        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={'auto'}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true
          }}
          pagination={true}
          modules={[EffectCoverflow, Pagination]}
          className='mySwiper'
        >
          {world?.worlds.map(island => <SwiperSlide  key={island.title}><img className='img-fluid'
                                                                             onClick={() => selectIsland('demoClassId', 'demoCourseId', island.id)}
                                                                             src={`/assets/images/islands/${island.category}.svg`}
                                                                             alt='island' /> </SwiperSlide>)}
        </Swiper> :
        <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 1599 872'>
          <defs>
            <path id='k' fill='#DCD100'
                  d='m809.041 315.6 1.869 1.441 3.569 1.032-.761 2.04 2.63 1.197-1.958 1.12-8.444-3.127-.326-2.827 3.421-.876Z'
                  className='k' />
            <path id='cw' fill='white'
                  d='M820.155 109.19c-.267 1.32-.524 2.706-.791 4.103a22.536 22.536 0 0 0 1.424-3.981c.178-.942.356-1.907.544-2.894.148-1.208.198-2.417.188-3.614-.435 1.862-.87 3.958-1.355 6.386h-.01Z'
                  className='cw' opacity='.1' />
            <path id='cA' fill='white'
                  d='m767.393 115.954.019-.066v-.022c2.927-9.747 5.824-20.203 8.761-29.107l.04-.122c.445-1.575.89-3.16 1.394-4.802-2.353 7.607-4.905 14.992-7.307 23.186-1.454 4.968-4.312 15.169-6.922 21.445h.297c.415-1.242.781-2.328 1.048-3.071.88-2.451 1.78-4.946 2.67-7.441Z'
                  className='cA' opacity='.1' />
            <path id='eo' fill='white'
                  d='M1343.61 66.71c.43-1.19.87-2.39 1.38-3.65-2.35 5.75-4.85 11.12-7.04 16.86-.16.43-.34.91-.54 1.43.06.16.13.32.19.47 1.9-5 3.85-10.21 5.96-15.01l.04-.09.01-.01Z'
                  className='eo' opacity='.1' />
            <path id='fp' fill='white'
                  d='M1349.44 81.57c.43-.61.83-1.24 1.12-1.92-.68.41-1.33.85-1.95 1.35-.17.31-.36.61-.58.91-.23.31.01.61.31.66.34-.35.71-.69 1.1-1Z'
                  className='fp' opacity='.1' />
            <path id='ib' fill='white'
                  d='m316.224 125.441.019-.038c2.332-5.918 4.684-12.578 7.311-18.524l.038-.086c.391-1.083.8-2.175 1.257-3.325-2.142 5.225-4.427 10.117-6.417 15.333-1.161 3.058-3.332 9.129-5.483 12.72h.304c.324-.703.6-1.321.819-1.739.705-1.406 1.438-2.859 2.161-4.332l-.009-.009Z'
                  className='ib' opacity='.1' />
            <path id='iX' fill='#DCD100'
                  d='m533.761 560.628 1.77 1.814 3.38 1.292-.724 2.556 2.485 1.52-1.856 1.396-7.978-3.923-.305-3.544 3.228-1.111Z'
                  className='iX' />
          </defs>
          <g className='ALL KEY SKILLS ISLANDS'>
            {world?.worlds.map(island => getIsland(island))}
          </g>
          <defs>
            <linearGradient id='b' x1='588.963' x2='985.335' y1='159.576' y2='159.576' className='b'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.15' stopColor='#C3CE39' />
              <stop offset='.32' stopColor='#C6DB39' />
              <stop offset='.66' stopColor='#D0D339' />
              <stop offset='1' stopColor='#E0C939' />
            </linearGradient>
            <linearGradient id='c' x1='765.84' x2='873.25' y1='116.697' y2='196.587' className='c'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='d' x1='760.303' x2='1029.61' y1='223.212' y2='223.212' className='d'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.15' stopColor='#C3CE39' />
              <stop offset='.32' stopColor='#C6DB39' />
              <stop offset='.66' stopColor='#D0D339' />
              <stop offset='1' stopColor='#E0C939' />
            </linearGradient>
            <linearGradient id='e' x1='760.303' x2='763.932' y1='227.847' y2='227.847' className='e'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.15' stopColor='#C3CE39' />
              <stop offset='.32' stopColor='#C6DB39' />
              <stop offset='.66' stopColor='#D0D339' />
              <stop offset='1' stopColor='#E0C939' />
            </linearGradient>
            <linearGradient id='f' x1='1001.99' x2='1006.98' y1='169.733' y2='169.733' className='f'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.15' stopColor='#C3CE39' />
              <stop offset='.32' stopColor='#C6DB39' />
              <stop offset='.66' stopColor='#D0D339' />
              <stop offset='1' stopColor='#E0C939' />
            </linearGradient>
            <linearGradient id='g' x1='889.134' x2='1029.61' y1='231.073' y2='231.073' className='g'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.15' stopColor='#C3CE39' />
              <stop offset='.32' stopColor='#C6DB39' />
              <stop offset='.66' stopColor='#D0D339' />
              <stop offset='1' stopColor='#E0C939' />
            </linearGradient>
            <linearGradient id='h' x1='765.306' x2='887.7' y1='262.088' y2='262.088' className='h'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.15' stopColor='#C3CE39' />
              <stop offset='.32' stopColor='#C6DB39' />
              <stop offset='.66' stopColor='#D0D339' />
              <stop offset='1' stopColor='#E0C939' />
            </linearGradient>
            <linearGradient id='l' x1='588.953' x2='500.989' y1='159.587' y2='159.587' className='l'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.15' stopColor='#C3CE39' />
              <stop offset='.32' stopColor='#C6DB39' />
              <stop offset='.66' stopColor='#D0D339' />
              <stop offset='1' stopColor='#E0C939' />
            </linearGradient>
            <linearGradient id='m' x1='991.674' x2='981.934' y1='145.318' y2='147.405' className='m'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#A97C50' />
              <stop offset='1' stopColor='#754C29' />
            </linearGradient>
            <linearGradient id='n' x1='993.894' x2='1003.2' y1='117.225' y2='130.924' className='n'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='o' x1='990.229' x2='1009.4' y1='118.07' y2='119.688' className='o'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='p' x1='990.057' x2='975.912' y1='114.428' y2='133.351' className='p'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='q' x1='986.743' x2='968.868' y1='117.049' y2='127.408' className='q'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='r' x1='984.362' x2='966.709' y1='115.473' y2='121.566' className='r'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='s' x1='989.218' x2='1003.14' y1='116.854' y2='110.484' className='s'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='t' x1='988.632' x2='976.546' y1='115.031' y2='104.326' className='t'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='u' x1='985.517' x2='973.138' y1='112.244' y2='111.65' className='u'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='v' x1='996.274' x2='988.326' y1='123.614' y2='113.533' className='v'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='w' x1='989.63' x2='990.328' y1='116.723' y2='132.996' className='w'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='x' x1='988.501' x2='1005.56' y1='117.544' y2='113.768' className='x'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='y' x1='985.261' x2='961.21' y1='115.727' y2='110.907' className='y'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='z' x1='984.882' x2='1001.24' y1='117.236' y2='105.488' className='z'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='A' x1='922.674' x2='912.934' y1='103.318' y2='105.405' className='A'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#A97C50' />
              <stop offset='1' stopColor='#754C29' />
            </linearGradient>
            <linearGradient id='B' x1='924.894' x2='934.2' y1='75.225' y2='88.924' className='B'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='C' x1='921.229' x2='940.396' y1='76.07' y2='77.688' className='C'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='D' x1='921.057' x2='906.912' y1='72.428' y2='91.35' className='D'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='E' x1='917.743' x2='899.868' y1='75.049' y2='85.408' className='E'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='F' x1='915.362' x2='897.709' y1='73.473' y2='79.566' className='F'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='G' x1='920.218' x2='934.142' y1='74.854' y2='68.484' className='G'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='H' x1='919.632' x2='907.546' y1='73.031' y2='62.326' className='H'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='I' x1='916.517' x2='904.138' y1='70.245' y2='69.65' className='I'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='J' x1='927.274' x2='919.326' y1='81.614' y2='71.532' className='J'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='K' x1='920.63' x2='921.328' y1='74.723' y2='90.996' className='K'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='L' x1='919.501' x2='936.555' y1='75.544' y2='71.769' className='L'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='M' x1='916.261' x2='892.21' y1='73.727' y2='68.907' className='M'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='N' x1='915.882' x2='932.24' y1='75.236' y2='63.488' className='N'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='O' x1='871.438' x2='861.697' y1='193.763' y2='195.858' className='O'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#A97C50' />
              <stop offset='1' stopColor='#754C29' />
            </linearGradient>
            <linearGradient id='P' x1='873.657' x2='882.963' y1='165.68' y2='179.391' className='P'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='Q' x1='870.002' x2='889.159' y1='166.526' y2='168.153' className='Q'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='R' x1='869.82' x2='855.675' y1='162.884' y2='181.806' className='R'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='S' x1='866.526' x2='848.642' y1='165.494' y2='175.852' className='S'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='T' x1='864.135' x2='846.484' y1='163.94' y2='170.024' className='T'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='U' x1='868.99' x2='882.915' y1='165.31' y2='158.94' className='U'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='V' x1='868.395' x2='856.309' y1='163.486' y2='152.781' className='V'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='W' x1='865.28' x2='852.901' y1='160.711' y2='160.117' className='W'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='X' x1='876.031' x2='868.07' y1='172.053' y2='161.956' className='X'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='Y' x1='869.393' x2='870.079' y1='165.168' y2='181.451' className='Y'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='Z' x1='868.264' x2='885.318' y1='165.999' y2='162.224' className='Z'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aa' x1='865.025' x2='840.983' y1='164.171' y2='159.352' className='aa'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='ab' x1='864.655' x2='881.013' y1='165.692' y2='153.944' className='ab'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='ac' x1='966.623' x2='958.361' y1='190.558' y2='191.935' className='ac'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#A97C50' />
              <stop offset='1' stopColor='#754C29' />
            </linearGradient>
            <linearGradient id='ad' x1='969.154' x2='976.372' y1='167.052' y2='179.018' className='ad'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='ae' x1='966.355' x2='982.374' y1='167.748' y2='169.846' className='ae'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='af' x1='966.371' x2='953.738' y1='164.283' y2='179.44' className='af'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='ag' x1='963.608' x2='948.261' y1='166.499' y2='174.427' className='ag'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='ah' x1='961.65' x2='946.664' y1='165.12' y2='169.515' className='ah'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='ai' x1='965.687' x2='977.577' y1='166.488' y2='161.716' className='ai'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aj' x1='964.909' x2='955.192' y1='165.036' y2='155.496' className='aj'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='ak' x1='962.645' x2='952.296' y1='162.673' y2='161.703' className='ak'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='al' x1='972.13' x2='965.879' y1='174.634' y2='165.774' className='al'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='am' x1='965.692' x2='965.494' y1='166.221' y2='179.893' className='am'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='an' x1='965.03' x2='979.449' y1='167.135' y2='164.636' className='an'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='ao' x1='962.232' x2='942.221' y1='165.59' y2='160.61' className='ao'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='ap' x1='962.035' x2='976.161' y1='166.526' y2='157.395' className='ap'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aq' x1='906.033' x2='897.926' y1='247.297' y2='249.033' className='aq'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#A97C50' />
              <stop offset='1' stopColor='#754C29' />
            </linearGradient>
            <linearGradient id='ar' x1='907.877' x2='915.622' y1='223.922' y2='235.324' className='ar'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='as' x1='904.833' x2='920.783' y1='224.616' y2='225.965' className='as'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='at' x1='904.69' x2='892.908' y1='221.609' y2='237.35' className='at'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='au' x1='901.933' x2='887.051' y1='223.763' y2='232.396' className='au'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='av' x1='899.937' x2='885.258' y1='222.444' y2='227.5' className='av'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aw' x1='903.99' x2='915.582' y1='223.61' y2='218.319' className='aw'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='ax' x1='903.52' x2='893.464' y1='222.071' y2='213.164' className='ax'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='ay' x1='900.916' x2='890.612' y1='219.782' y2='219.288' className='ay'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='az' x1='910.932' x2='904.305' y1='230.906' y2='222.5' className='az'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aA' x1='904.362' x2='904.926' y1='223.51' y2='237.046' className='aA'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aB' x1='903.398' x2='917.588' y1='224.196' y2='221.052' className='aB'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aC' x1='900.7' x2='880.69' y1='222.645' y2='218.635' className='aC'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aD' x1='900.409' x2='914.02' y1='223.96' y2='214.183' className='aD'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aE' x1='673.154' x2='663.644' y1='129.867' y2='132.771' className='aE'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#A97C50' />
              <stop offset='1' stopColor='#754C29' />
            </linearGradient>
            <linearGradient id='aF' x1='672.645' x2='683.136' y1='101.781' y2='114.432' className='aF'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aG' x1='669.395' x2='688.623' y1='103.067' y2='103.1' className='aG'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aH' x1='668.789' x2='656.456' y1='99.066' y2='119.446' className='aH'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aI' x1='665.857' x2='648.823' y1='102.08' y2='114.096' className='aI'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aJ' x1='663.369' x2='646.21' y1='100.825' y2='108.445' className='aJ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aK' x1='668.317' x2='681.728' y1='101.694' y2='94.078' className='aK'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aL' x1='667.324' x2='654.424' y1='100.174' y2='90.697' className='aL'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aM' x1='664.228' x2='651.824' y1='97.658' y2='98.092' className='aM'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aN' x1='675.639' x2='666.843' y1='107.999' y2='98.792' className='aN'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aO' x1='668.37' x2='670.752' y1='101.503' y2='117.595' className='aO'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aP' x1='667.685' x2='684.439' y1='102.577' y2='97.35' className='aP'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aQ' x1='664.204' x2='639.914' y1='101.221' y2='98.424' className='aQ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aR' x1='664' x2='679.348' y1='102.451' y2='89.144' className='aR'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aS' x1='734.538' x2='725.993' y1='104.777' y2='107.399' className='aS'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#A97C50' />
              <stop offset='1' stopColor='#754C29' />
            </linearGradient>
            <linearGradient id='aT' x1='734.13' x2='743.591' y1='79.504' y2='90.825' className='aT'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aU' x1='731.106' x2='748.384' y1='80.668' y2='80.643' className='aU'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aV' x1='730.588' x2='719.548' y1='77.174' y2='95.525' className='aV'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aW' x1='727.908' x2='712.63' y1='79.865' y2='90.708' className='aW'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aX' x1='725.678' x2='710.285' y1='78.728' y2='85.613' className='aX'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aY' x1='730.121' x2='742.16' y1='79.494' y2='72.621' className='aY'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='aZ' x1='729.287' x2='717.68' y1='78.113' y2='69.625' className='aZ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='ba' x1='726.459' x2='715.311' y1='75.816' y2='76.236' className='ba'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bb' x1='737.096' x2='729.162' y1='85.43' y2='77.175' className='bb'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bc' x1='730.274' x2='732.458' y1='79.342' y2='93.798' className='bc'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bd' x1='729.556' x2='744.603' y1='80.264' y2='75.525' className='bd'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='be' x1='726.46' x2='704.63' y1='78.979' y2='76.535' className='be'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bf' x1='726.249' x2='740.01' y1='80.21' y2='68.207' className='bf'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bg' x1='699.159' x2='689.281' y1='171.643' y2='173.105' className='bg'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#A97C50' />
              <stop offset='1' stopColor='#754C29' />
            </linearGradient>
            <linearGradient id='bh' x1='702.803' x2='711.096' y1='143.86' y2='158.32' className='bh'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bi' x1='699.407' x2='718.447' y1='144.453' y2='147.31' className='bi'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bj' x1='699.368' x2='683.974' y1='140.457' y2='158.159' className='bj'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bk' x1='696.059' x2='677.61' y1='142.94' y2='152.018' className='bk'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bl' x1='693.776' x2='675.848' y1='141.264' y2='146.159' className='bl'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bm' x1='698.538' x2='712.792' y1='142.966' y2='137.56' className='bm'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bn' x1='697.788' x2='686.427' y1='141.307' y2='129.689' className='bn'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bo' x1='695.096' x2='682.764' y1='138.299' y2='136.912' className='bo'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bp' x1='704.958' x2='697.727' y1='150.755' y2='140.024' className='bp'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bq' x1='698.635' x2='698.011' y1='142.812' y2='159.088' className='bq'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='br' x1='697.771' x2='714.991' y1='143.673' y2='141.015' className='br'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bs' x1='694.553' x2='670.798' y1='141.799' y2='135.41' className='bs'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bt' x1='694.173' x2='711.197' y1='142.963' y2='132.435' className='bt'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bu' x1='848.704' x2='840.056' y1='85.658' y2='86.463' className='bu'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#A97C50' />
              <stop offset='1' stopColor='#754C29' />
            </linearGradient>
            <linearGradient id='bv' x1='853.099' x2='859.509' y1='61.729' y2='74.767' className='bv'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bw' x1='850.156' x2='866.547' y1='62.126' y2='65.487' className='bw'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bx' x1='850.379' x2='836.209' y1='58.62' y2='73.059' className='bx'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='by' x1='847.37' x2='831.043' y1='60.617' y2='67.562' className='by'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bz' x1='845.463' x2='829.762' y1='59.021' y2='62.416' className='bz'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bA' x1='849.515' x2='862.071' y1='60.785' y2='56.792' className='bA'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bB' x1='848.872' x2='839.592' y1='59.273' y2='48.556' className='bB'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bC' x1='846.705' x2='836.086' y1='56.585' y2='54.813' className='bC'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bD' x1='855.426' x2='849.719' y1='69.467' y2='59.724' className='bD'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bE' x1='849.549' x2='848.082' y1='60.578' y2='74.605' className='bE'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bF' x1='848.806' x2='863.816' y1='61.358' y2='59.852' className='bF'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bG' x1='846.065' x2='825.729' y1='59.576' y2='52.899' className='bG'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bH' x1='845.732' x2='860.9' y1='60.487' y2='52.249' className='bH'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bI' x1='773.056' x2='764.067' y1='169.175' y2='171.625' className='bI'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#A97C50' />
              <stop offset='1' stopColor='#754C29' />
            </linearGradient>
            <linearGradient id='bJ' x1='773.473' x2='782.93' y1='142.748' y2='154.945' className='bJ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bK' x1='770.266' x2='788.271' y1='143.847' y2='144.402' className='bK'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bL' x1='769.909' x2='757.733' y1='140.224' y2='158.855' className='bL'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bM' x1='767.027' x2='750.776' y1='142.951' y2='153.665' className='bM'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bN' x1='764.696' x2='748.445' y1='141.638' y2='148.271' className='bN'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bO' x1='769.33' x2='782.072' y1='142.646' y2='135.921' className='bO'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bP' x1='768.489' x2='756.66' y1='141.055' y2='131.771' className='bP'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bQ' x1='765.578' x2='753.96' y1='138.671' y2='138.739' className='bQ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bR' x1='776.5' x2='768.514' y1='149.207' y2='140.274' className='bR'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bS' x1='769.526' x2='771.202' y1='142.423' y2='157.577' className='bS'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bT' x1='768.681' x2='784.492' y1='143.427' y2='139.013' className='bT'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bU' x1='765.469' x2='742.781' y1='141.976' y2='138.697' className='bU'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bV' x1='765.289' x2='780.013' y1='143.241' y2='131.286' className='bV'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='bW' x1='814.892' x2='816.535' y1='10.768' y2='13.649' className='bW'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='bX' x1='368.923' x2='369.68' y1='97.132' y2='99.014' className='bX'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='bY' x1='402.578' x2='404.419' y1='1263.12' y2='1263.34' className='bY'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='bZ' x1='775.125' x2='776.08' y1='36.196' y2='37.107' className='bZ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='ca' x1='821.975' x2='823.04' y1='29.51' y2='30.519' className='ca'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='cb' x1='802.199' x2='803.718' y1='103.192' y2='104.625' className='cb'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='cc' x1='808.71' x2='809.983' y1='34.723' y2='36.689' className='cc'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='cd' x1='830.559' x2='832.473' y1='96.139' y2='99.093' className='cd'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='ce' x1='881.304' x2='881.053' y1='48.256' y2='126.002' className='ce'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cf' x1='918.746' x2='934.307' y1='122.664' y2='172.109' className='cf'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cg' x1='870.469' x2='907.069' y1='38.887' y2='155.203' className='cg'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='ch' x1='878.39' x2='901.301' y1='74.121' y2='142.385' className='ch'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ci' x1='925.907' x2='941.479' y1='117.476' y2='166.95' className='ci'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='cj' x1='925.995' x2='935.444' y1='117.807' y2='147.864' className='cj'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ck' x1='864.032' x2='876.222' y1='61.356' y2='100.096' className='ck'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cl' x1='916.416' x2='921.625' y1='69.661' y2='86.208' className='cl'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cm' x1='862.318' x2='868.16' y1='94.284' y2='112.869' className='cm'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cn' x1='881.129' x2='880.925' y1='50.847' y2='114.776' className='cn'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='co' x1='869.69' x2='869.448' y1='43.685' y2='118.455' className='co'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cp' x1='863.438' x2='863.186' y1='38.642' y2='119.021' className='cp'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cq' x1='876.331' x2='876.113' y1='48.742' y2='116.653' className='cq'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cr' x1='868.506' x2='868.267' y1='42.628' y2='118.618' className='cr'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cs' x1='878.758' x2='878.544' y1='49.968' y2='115.702' className='cs'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ct' x1='861.911' x2='861.6' y1='12.382' y2='113.135' className='ct'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cu' x1='883.006' x2='882.75' y1='46.85' y2='125.603' className='cu'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cv' x1='910.024' x2='926.223' y1='125.198' y2='176.67' className='cv'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cx' x1='838.268' x2='880.344' y1='31.565' y2='165.292' className='cx'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='cy' x1='847.253' x2='873.629' y1='72.852' y2='151.456' className='cy'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cz' x1='867.672' x2='867.381' y1='25.149' y2='117.749' className='cz'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cB' x1='863.866' x2='863.599' y1='32.262' y2='119.074' className='cB'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cC' x1='809.496' x2='838.25' y1='50.356' y2='50.356' className='cC'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cD' x1='774.383' x2='795.514' y1='108.813' y2='108.813' className='cD'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cE' x1='791.836' x2='803.047' y1='29.687' y2='41.04' className='cE'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cF' x1='788.899' x2='800.974' y1='74.395' y2='90.558' className='cF'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cG' x1='775.313' x2='795.841' y1='103.779' y2='103.779' className='cG'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cH' x1='741.961' x2='789.153' y1='32.936' y2='96.061' className='cH'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cI' x1='775.619' x2='795.574' y1='96.572' y2='96.572' className='cI'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cJ' x1='767.225' x2='793.873' y1='58.184' y2='93.825' className='cJ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cK' x1='775.264' x2='795.949' y1='105.786' y2='105.786' className='cK'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cL' x1='761.628' x2='784.705' y1='71.701' y2='102.564' className='cL'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cM' x1='775.343' x2='795.811' y1='104.711' y2='104.711' className='cM'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cN' x1='777.765' x2='783.791' y1='95.707' y2='103.773' className='cN'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cO' x1='793.596' x2='826.908' y1='89.697' y2='123.37' className='cO'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cP' x1='806.193' x2='822.207' y1='105.975' y2='122.158' className='cP'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='cQ' x1='774.673' x2='776.102' y1='64.122' y2='66.312' className='cQ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='cR' x1='811.279' x2='812.507' y1='29.6' y2='31.515' className='cR'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='cS' x1='1235.54' x2='1109.11' y1='375.28' y2='375.28' className='cS'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='cT' x1='1233.53' x2='1109.59' y1='363.19' y2='363.19' className='cT'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='cU' x1='1519.25' x2='1239.61' y1='379.26' y2='379.26' className='cU'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='cV' x1='1375.44' x2='1365.94' y1='277.07' y2='277.07' className='cV'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#5DC7EF' />
              <stop offset='1' stopColor='#D0EBF6' />
            </linearGradient>
            <linearGradient id='cW' x1='1332.13' x2='1323.21' y1='262.95' y2='262.95' className='cW'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#5DC7EF' />
              <stop offset='1' stopColor='#D0EBF6' />
            </linearGradient>
            <linearGradient id='cX' x1='1406.13' x2='1369.37' y1='259.39' y2='259.39' className='cX'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#5DC7EF' />
              <stop offset='1' stopColor='#D0EBF6' />
            </linearGradient>
            <linearGradient id='cY' x1='1408.11' x2='921.31' y1='568.57' y2='255.74' className='cY'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B5E8FA' />
              <stop offset='.19' stopColor='#AAE4F9' />
              <stop offset='.52' stopColor='#8FDCF7' />
              <stop offset='.95' stopColor='#63CDF5' />
              <stop offset='1' stopColor='#5DCCF5' />
            </linearGradient>
            <linearGradient id='cZ' x1='1516.16' x2='1321.37' y1='182.51' y2='182.51' className='cZ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='da' x1='1515.87' x2='1355' y1='167.99' y2='167.99' className='da'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='db' x1='1516.16' x2='1321.37' y1='188.53' y2='188.53' className='db'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='dc' x1='1356.76' x2='1336.36' y1='152.66' y2='152.66' className='dc'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='dd' x1='1407.96' x2='1330.61' y1='241.78' y2='241.78' className='dd'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#5DC7EF' />
              <stop offset='1' stopColor='#D0EBF6' />
            </linearGradient>
            <linearGradient id='de' x1='1407.39' x2='1343.74' y1='246.85' y2='246.85' className='de'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#5DC7EF' />
              <stop offset='1' stopColor='#D0EBF6' />
            </linearGradient>
            <linearGradient id='df' x1='1406.32' x2='1353.19' y1='251.4' y2='251.4' className='df'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#5DC7EF' />
              <stop offset='1' stopColor='#D0EBF6' />
            </linearGradient>
            <linearGradient id='dg' x1='1402.05' x2='1351.26' y1='249.46' y2='249.46' className='dg'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#5DC7EF' />
              <stop offset='1' stopColor='#D0EBF6' />
            </linearGradient>
            <linearGradient id='dh' x1='1402.88' x2='1375.18' y1='251.87' y2='251.87' className='dh'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#5DC7EF' />
              <stop offset='1' stopColor='#D0EBF6' />
            </linearGradient>
            <linearGradient id='di' x1='1365.51' x2='1360.38' y1='264.69' y2='280.09' className='di'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#5DC7EF' />
              <stop offset='1' stopColor='#D0EBF6' />
            </linearGradient>
            <linearGradient id='dj' x1='1361.77' x2='1356.64' y1='263.76' y2='279.14' className='dj'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#5DC7EF' />
              <stop offset='1' stopColor='#D0EBF6' />
            </linearGradient>
            <linearGradient id='dk' x1='1356.5' x2='1351.39' y1='262.35' y2='277.67' className='dk'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#5DC7EF' />
              <stop offset='1' stopColor='#D0EBF6' />
            </linearGradient>
            <linearGradient id='dl' x1='1351.88' x2='1346.8' y1='261.15' y2='276.41' className='dl'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#5DC7EF' />
              <stop offset='1' stopColor='#D0EBF6' />
            </linearGradient>
            <linearGradient id='dm' x1='1348.86' x2='1343.78' y1='260.4' y2='275.63' className='dm'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#5DC7EF' />
              <stop offset='1' stopColor='#D0EBF6' />
            </linearGradient>
            <linearGradient id='dn' x1='1344.46' x2='1339.42' y1='259.29' y2='274.42' className='dn'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#5DC7EF' />
              <stop offset='1' stopColor='#D0EBF6' />
            </linearGradient>
            <linearGradient id='do' x1='1340.47' x2='1335.4' y1='258.19' y2='273.41' className='do'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#5DC7EF' />
              <stop offset='1' stopColor='#D0EBF6' />
            </linearGradient>
            <linearGradient id='dp' x1='1336.88' x2='1331.8' y1='257.24' y2='272.48' className='dp'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#5DC7EF' />
              <stop offset='1' stopColor='#D0EBF6' />
            </linearGradient>
            <linearGradient id='dq' x1='1334.47' x2='1329.37' y1='256.59' y2='271.88' className='dq'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#5DC7EF' />
              <stop offset='1' stopColor='#D0EBF6' />
            </linearGradient>
            <linearGradient id='dr' x1='1295.99' x2='1291.31' y1='345.46' y2='345.46' className='dr'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#5DC7EF' />
              <stop offset='1' stopColor='#D0EBF6' />
            </linearGradient>
            <linearGradient id='ds' x1='1267.15' x2='1260.98' y1='332.44' y2='332.44' className='ds'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#5DC7EF' />
              <stop offset='1' stopColor='#D0EBF6' />
            </linearGradient>
            <linearGradient id='dt' x1='1513.49' x2='1472.62' y1='239.53' y2='239.53' className='dt'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='du' x1='1512.47' x2='1472.55' y1='249.42' y2='249.42' className='du'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='dv' x1='1469.58' x2='1411.98' y1='289.74' y2='289.74' className='dv'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='dw' x1='1284.13' x2='1249.63' y1='250.91' y2='250.91' className='dw'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='dx' x1='1256.39' x2='1235.94' y1='257.85' y2='257.85' className='dx'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='dy' x1='1460.17' x2='1420.11' y1='280.44' y2='280.44' className='dy'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='dz' x1='1509.89' x2='1479.56' y1='235.65' y2='235.65' className='dz'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='dA' x1='1514.8' x2='1511.01' y1='255.11' y2='255.11' className='dA'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='dB' x1='1514.23' x2='1502.94' y1='271.02' y2='271.02' className='dB'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='dC' x1='1503.22' x2='1499.91' y1='261.12' y2='261.12' className='dC'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='dD' x1='1500.25' x2='1499.24' y1='252.06' y2='252.06' className='dD'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='dE' x1='1222.52' x2='997.75' y1='555.13' y2='410.68' className='dE'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B5E8FA' />
              <stop offset='.19' stopColor='#AAE4F9' />
              <stop offset='.52' stopColor='#8FDCF7' />
              <stop offset='.95' stopColor='#63CDF5' />
              <stop offset='1' stopColor='#5DCCF5' />
            </linearGradient>
            <linearGradient id='dF' x1='1404.1' x2='1405.16' y1='77.98' y2='79.12' className='dF'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='dG' x1='1470.66' x2='1479.96' y1='89.321' y2='122.451' className='dG'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='dH' x1='1421.12' x2='1447.35' y1='15.476' y2='108.952' className='dH'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='dI' x1='1427.4' x2='1444.57' y1='40.21' y2='97.564' className='dI'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='dJ' x1='1449.49' x2='1475.72' y1='7.519' y2='100.986' className='dJ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='dK' x1='1456.87' x2='1473.32' y1='34.625' y2='89.606' className='dK'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='dL' x1='1390.5' x2='1416.72' y1='24.066' y2='117.541' className='dL'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='dM' x1='1397.37' x2='1413.83' y1='52.418' y2='107.407' className='dM'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='dN' x1='1444.25' x2='1470.48' y1='8.989' y2='102.469' className='dN'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='dO' x1='1451.59' x2='1468.05' y1='36.189' y2='91.192' className='dO'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='dP' x1='1385.7' x2='1386.79' y1='21.946' y2='23.832' className='dP'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='dQ' x1='1445.11' x2='1471.33' y1='8.748' y2='102.215' className='dQ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='dR' x1='1452.47' x2='1468.92' y1='35.935' y2='90.924' className='dR'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='dS' x1='1445.06' x2='1471.28' y1='8.758' y2='102.234' className='dS'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='dT' x1='1452.41' x2='1468.87' y1='35.954' y2='90.943' className='dT'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='dU' x1='1384.08' x2='1385.17' y1='22.873' y2='24.76' className='dU'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='dV' x1='1443.59' x2='1469.81' y1='9.172' y2='102.647' className='dV'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='dW' x1='1450.92' x2='1467.38' y1='36.391' y2='91.38' className='dW'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='dX' x1='1386.3' x2='1387.4' y1='21.594' y2='23.483' className='dX'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='dY' x1='1445.76' x2='1471.99' y1='8.565' y2='102.03' className='dY'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='dZ' x1='1453.11' x2='1469.57' y1='35.738' y2='90.727' className='dZ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ea' x1='1390.19' x2='1401.12' y1='32.434' y2='71.378' className='ea'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='eb' x1='1465.13' x2='1469.43' y1='41.48' y2='56.773' className='eb'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ec' x1='1403.47' x2='1404.53' y1='18.15' y2='19.29' className='ec'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='ed' x1='1473.12' x2='1477.42' y1='39.233' y2='54.54' className='ed'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ee' x1='1473.28' x2='1477.57' y1='39.186' y2='54.488' className='ee'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ef' x1='1403.48' x2='1404.55' y1='18.13' y2='19.27' className='ef'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='eg' x1='1473.15' x2='1477.44' y1='39.218' y2='54.511' className='eg'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='eh' x1='1397.65' x2='1402.22' y1='62.956' y2='79.202' className='eh'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ei' x1='1396.33' x2='1396.15' y1='19.586' y2='83.538' className='ei'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ej' x1='1428.88' x2='1428.69' y1='19.673' y2='83.634' className='ej'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ek' x1='1461.23' x2='1470.52' y1='91.964' y2='125.104' className='ek'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='el' x1='1386.25' x2='1412.48' y1='25.258' y2='118.737' className='el'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='em' x1='1393.34' x2='1409.79' y1='53.625' y2='108.614' className='em'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='en' x1='1408.29' x2='1408.1' y1='19.622' y2='83.573' className='en'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ep' x1='1399.86' x2='1399.68' y1='19.59' y2='83.55' className='ep'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='eq' x1='1385.7' x2='1424.4' y1='37.55' y2='37.55' className='eq'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='er' x1='1385.71' x2='1424.41' y1='44.72' y2='44.72' className='er'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='es' x1='1385.71' x2='1424.39' y1='41.57' y2='41.57' className='es'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='et' x1='1399.66' x2='1425.89' y1='21.502' y2='114.968' className='et'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='eu' x1='1406.53' x2='1422.98' y1='49.685' y2='104.674' className='eu'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ev' x1='1344.28' x2='1367.75' y1='81.99' y2='81.99' className='ev'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ew' x1='1369.23' x2='1379.68' y1='10.38' y2='22.249' className='ew'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ex' x1='1447.66' x2='1447.48' y1='19.728' y2='83.68' className='ex'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ey' x1='1413.57' x2='1439.8' y1='17.596' y2='111.063' className='ey'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='ez' x1='1420.6' x2='1437.05' y1='45.468' y2='100.457' className='ez'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='eA' x1='1361.86' x2='1369.77' y1='56.2' y2='68.07' className='eA'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='eB' x1='1405.85' x2='1432.08' y1='19.768' y2='113.235' className='eB'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='eC' x1='1412.74' x2='1429.2' y1='47.816' y2='102.81' className='eC'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='eD' x1='1344.28' x2='1367.75' y1='73.96' y2='73.96' className='eD'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='eE' x1='1353.24' x2='1361.16' y1='61.94' y2='73.81' className='eE'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='eF' x1='1367.96' x2='1392.85' y1='70.57' y2='98.791' className='eF'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='eG' x1='1376.3' x2='1377.37' y1='78.63' y2='79.77' className='eG'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='eH' x1='1368.67' x2='1393.57' y1='69.94' y2='98.16' className='eH'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='eI' x1='1368.67' x2='1393.56' y1='69.95' y2='98.15' className='eI'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='eJ' x1='1377.17' x2='1378.24' y1='77.82' y2='78.95' className='eJ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='eK' x1='1369.58' x2='1394.47' y1='69.14' y2='97.359' className='eK'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='eL' x1='1374.72' x2='1375.79' y1='80.12' y2='81.26' className='eL'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='eM' x1='1367.06' x2='1391.95' y1='71.37' y2='99.579' className='eM'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='eN' x1='1370.98' x2='1395.87' y1='67.91' y2='96.12' className='eN'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='eO' x1='1368.73' x2='1396.03' y1='70.6' y2='101.54' className='eO'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='eP' x1='1380.63' x2='1391.45' y1='82.57' y2='94.84' className='eP'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='eQ' x1='1370.81' x2='1395.7' y1='68.06' y2='96.27' className='eQ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='eR' x1='1368.99' x2='1393.88' y1='69.66' y2='97.87' className='eR'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='eS' x1='1380.52' x2='1391.53' y1='82.73' y2='95.2' className='eS'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='eT' x1='1475.82' x2='1480.11' y1='38.474' y2='53.777' className='eT'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='eU' x1='1385.71' x2='1424.4' y1='26.47' y2='26.47' className='eU'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='eV' x1='1385.7' x2='1424.41' y1='32.16' y2='32.16' className='eV'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='eW' x1='1385.72' x2='1424.38' y1='31.58' y2='31.58' className='eW'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='eX' x1='1384.4' x2='1409.3' y1='56.07' y2='84.29' className='eX'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='eY' x1='1356.85' x2='1381.75' y1='80.37' y2='108.581' className='eY'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='eZ' x1='1391.71' x2='1417.94' y1='23.74' y2='117.206' className='eZ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='fa' x1='1398.62' x2='1415.07' y1='52.051' y2='107.04' className='fa'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='fb' x1='1399.48' x2='1425.71' y1='21.549' y2='115.025' className='fb'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='fc' x1='1406.5' x2='1422.95' y1='49.69' y2='104.679' className='fc'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='fd' x1='1393.72' x2='1419.95' y1='23.157' y2='116.645' className='fd'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='fe' x1='1400.63' x2='1417.08' y1='51.437' y2='106.435' className='fe'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ff' x1='1388.66' x2='1414.88' y1='24.588' y2='118.064' className='ff'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='fg' x1='1395.54' x2='1412' y1='52.967' y2='107.956' className='fg'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='fh' x1='1386.25' x2='1412.44' y1='25.317' y2='118.756' className='fh'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='fi' x1='1393.1' x2='1409.54' y1='53.741' y2='108.716' className='fi'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='fj' x1='1344.28' x2='1367.73' y1='88.44' y2='88.44' className='fj'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='fk' x1='1398.75' x2='1424.99' y1='21.752' y2='115.223' className='fk'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='fl' x1='1405.66' x2='1422.12' y1='49.943' y2='104.932' className='fl'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='fm' x1='1347.16' x2='1355.08' y1='66' y2='77.86' className='fm'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='fn' x1='1392.4' x2='1418.62' y1='23.551' y2='117.013' className='fn'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='fo' x1='1399.31' x2='1415.76' y1='51.84' y2='106.829' className='fo'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='fq' x1='1431.76' x2='1431.58' y1='19.686' y2='83.638' className='fq'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='fr' x1='1430.24' x2='1430.06' y1='19.676' y2='83.637' className='fr'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='fs' x1='1352.82' x2='1363.27' y1='24.84' y2='36.7' className='fs'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ft' x1='1433.05' x2='1459.28' y1='12.136' y2='105.602' className='ft'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='fu' x1='1440.17' x2='1456.63' y1='39.608' y2='94.601' className='fu'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='fv' x1='1442.03' x2='1468.26' y1='9.606' y2='103.086' className='fv'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='fw' x1='1449.26' x2='1465.71' y1='36.889' y2='91.887' className='fw'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='fx' x1='1452.13' x2='1478.35' y1='6.783' y2='100.25' className='fx'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='fy' x1='1459.41' x2='1475.86' y1='33.855' y2='88.844' className='fy'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='fz' x1='1407.84' x2='1407.65' y1='19.564' y2='83.581' className='fz'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='fA' x1='1338.4' x2='1339.49' y1='49.83' y2='51.717' className='fA'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='fB' x1='1450.34' x2='1476.57' y1='7.278' y2='100.745' className='fB'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='fC' x1='1457.73' x2='1474.19' y1='34.358' y2='89.347' className='fC'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='fD' x1='1389.57' x2='1390.67' y1='16.749' y2='18.648' className='fD'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='fE' x1='1450.29' x2='1476.52' y1='7.297' y2='100.764' className='fE'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='fF' x1='1457.68' x2='1474.14' y1='34.376' y2='89.365' className='fF'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='fG' x1='1451' x2='1477.23' y1='7.095' y2='100.56' className='fG'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='fH' x1='1458.38' x2='1474.85' y1='34.16' y2='89.154' className='fH'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='fI' x1='1390.18' x2='1391.27' y1='16.4' y2='18.287' className='fI'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='fJ' x1='1448.82' x2='1475.05' y1='7.698' y2='101.186' className='fJ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='fK' x1='1456.19' x2='1472.65' y1='34.813' y2='89.815' className='fK'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='fL' x1='1387.96' x2='1389.05' y1='17.689' y2='19.576' className='fL'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='fM' x1='1344.56' x2='1358.69' y1='23.24' y2='40.87' className='fM'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='fN' x1='1363.18' x2='1377.91' y1='14.79' y2='31.49' className='fN'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='fO' x1='1337.64' x2='1351.64' y1='63.65' y2='79.52' className='fO'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='fP' x1='1345.87' x2='1347' y1='39.202' y2='42.836' className='fP'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='fQ' x1='1380.32' x2='1381.46' y1='91.413' y2='95.052' className='fQ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='fR' x1='1390.5' x2='1392.18' y1='28.803' y2='34.192' className='fR'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='fS' x1='1449.86' x2='1451.44' y1='139.217' y2='144.844' className='fS'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='fT' x1='1451.22' x2='1451.98' y1='144.059' y2='146.772' className='fT'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='fU' x1='1378.82' x2='1379.23' y1='147.85' y2='148.2' className='fU'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='fV' x1='1379.17' x2='1379.36' y1='148.15' y2='148.32' className='fV'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='fW' x1='1369.28' x2='1375.89' y1='123.77' y2='128.33' className='fW'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='fX' x1='1369.36' x2='1372.68' y1='138.7' y2='145.04' className='fX'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='fY' x1='1371.48' x2='1374.35' y1='152.7' y2='158.2' className='fY'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='fZ' x1='1365.6' x2='1366.23' y1='110.36' y2='110.95' className='fZ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='ga' x1='1318.12' x2='1319.2' y1='31.979' y2='32.221' className='ga'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='gb' x1='1307.77' x2='1308.85' y1='64.453' y2='64.695' className='gb'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='gc' x1='1377.01' x2='1377.34' y1='134.9' y2='135.21' className='gc'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='gd' x1='1310.42' x2='1311.83' y1='94.282' y2='94.763' className='gd'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='ge' x1='1364.36' x2='1364.89' y1='145.26' y2='145.76' className='ge'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='gf' x1='1364.21' x2='1373.74' y1='103.79' y2='103.79' className='gf'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='gg' x1='1377.6' x2='1381.17' y1='119.98' y2='123.02' className='gg'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='gh' x1='1372.83' x2='1372.75' y1='97.94' y2='100.15' className='gh'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='gi' x1='322.612' x2='377.01' y1='185.906' y2='185.906' className='gi'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='gj' x1='289.948' x2='322.412' y1='244.671' y2='244.671' className='gj'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='gk' x1='264.062' x2='314.339' y1='297.792' y2='297.792' className='gk'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='gl' x1='360.654' x2='409.017' y1='290.373' y2='290.373' className='gl'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='gm' x1='314.338' x2='380.237' y1='305.231' y2='305.231' className='gm'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='gn' x1='117.508' x2='152.2' y1='453.292' y2='453.292' className='gn'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='go' x1='140.842' x2='264.71' y1='408.349' y2='408.349' className='go'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='gp' x1='280.561' x2='420.803' y1='429.381' y2='429.381' className='gp'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='gq' x1='406.075' x2='558.741' y1='456.465' y2='456.465' className='gq'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='gr' x1='463.32' x2='681.314' y1='474.685' y2='474.685' className='gr'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='gs' x1='459.493' x2='677.091' y1='473.003' y2='456.05' className='gs'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8B6B4' />
              <stop offset='1' stopColor='#9F9D9B' />
            </linearGradient>
            <linearGradient id='gt' x1='362.739' x2='643.338' y1='512.797' y2='512.797' className='gt'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='gu' x1='215.976' x2='350.687' y1='494.9' y2='494.9' className='gu'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C939' />
              <stop offset='.07' stopColor='#C3CE39' />
              <stop offset='.14' stopColor='#C6DB39' />
              <stop offset='.15' stopColor='#C6DB39' />
              <stop offset='.48' stopColor='#D4DC54' />
              <stop offset='.77' stopColor='#DDDC65' />
              <stop offset='1' stopColor='#E0DD6B' />
            </linearGradient>
            <linearGradient id='gv' x1='263.443' x2='274.887' y1='529.241' y2='529.241' className='gv'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8B6B4' />
              <stop offset='1' stopColor='#9F9D9B' />
            </linearGradient>
            <linearGradient id='gw' x1='494.808' x2='483.837' y1='428.252' y2='430.666' className='gw'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#A97C50' />
              <stop offset='1' stopColor='#754C29' />
            </linearGradient>
            <linearGradient id='gx' x1='497.714' x2='506.35' y1='400.128' y2='415.068' className='gx'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gy' x1='493.708' x2='515.307' y1='400.862' y2='403.355' className='gy'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gz' x1='493.446' x2='479.333' y1='397.033' y2='417.349' className='gz'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gA' x1='489.769' x2='470.544' y1='399.649' y2='411.636' className='gA'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gB' x1='487.111' x2='467.532' y1='398.025' y2='405.167' className='gB'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gC' x1='492.575' x2='507.811' y1='399.521' y2='392.071' className='gC'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gD' x1='491.85' x2='479.838' y1='397.839' y2='385.403' className='gD'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gE' x1='488.496' x2='474.521' y1='394.897' y2='393.866' className='gE'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gF' x1='499.323' x2='491.792' y1='405.194' y2='393.997' className='gF'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gG' x1='492.857' x2='493.154' y1='399.447' y2='415.852' className='gG'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gH' x1='491.777' x2='510.928' y1='400.244' y2='395.88' className='gH'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gI' x1='488.12' x2='461.311' y1='398.442' y2='391.835' className='gI'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gJ' x1='487.621' x2='504.892' y1='399.777' y2='386.39' className='gJ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gK' x1='237.752' x2='230.331' y1='405.246' y2='406.88' className='gK'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#A97C50' />
              <stop offset='1' stopColor='#754C29' />
            </linearGradient>
            <linearGradient id='gL' x1='239.7' x2='245.541' y1='386.224' y2='396.334' className='gL'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gM' x1='237.011' x2='251.615' y1='386.703' y2='388.393' className='gM'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gN' x1='236.838' x2='227.3' y1='384.128' y2='397.876' className='gN'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gO' x1='234.341' x2='221.331' y1='385.888' y2='393.996' className='gO'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gP' x1='232.551' x2='219.306' y1='384.785' y2='389.612' className='gP'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gQ' x1='236.246' x2='246.545' y1='385.809' y2='380.776' className='gQ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gR' x1='235.749' x2='227.633' y1='384.658' y2='376.242' className='gR'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gS' x1='233.49' x2='224.024' y1='382.673' y2='381.974' className='gS'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gT' x1='242.493' x2='237.403' y1='392.134' y2='384.565' className='gT'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gU' x1='236.422' x2='236.623' y1='385.755' y2='396.857' className='gU'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gV' x1='235.696' x2='248.653' y1='386.283' y2='383.336' className='gV'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gW' x1='233.229' x2='215.099' y1='385.044' y2='380.579' className='gW'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gX' x1='232.893' x2='244.575' y1='385.972' y2='376.92' className='gX'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='gY' x1='199.872' x2='192.451' y1='391.815' y2='393.448' className='gY'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#A97C50' />
              <stop offset='1' stopColor='#754C29' />
            </linearGradient>
            <linearGradient id='gZ' x1='201.829' x2='207.661' y1='372.793' y2='382.902' className='gZ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='ha' x1='199.13' x2='213.736' y1='373.272' y2='374.952' className='ha'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='hb' x1='198.958' x2='189.419' y1='370.697' y2='384.444' className='hb'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='hc' x1='196.46' x2='183.451' y1='372.456' y2='380.564' className='hc'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='hd' x1='194.67' x2='181.425' y1='371.363' y2='376.19' className='hd'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='he' x1='198.366' x2='208.675' y1='372.377' y2='367.336' className='he'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='hf' x1='197.869' x2='189.753' y1='371.226' y2='362.81' className='hf'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='hg' x1='195.609' x2='186.153' y1='369.241' y2='368.543' className='hg'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='hh' x1='204.588' x2='199.497' y1='378.663' y2='371.094' className='hh'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='hi' x1='198.551' x2='198.753' y1='372.324' y2='383.426' className='hi'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='hj' x1='197.815' x2='210.773' y1='372.851' y2='369.905' className='hj'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='hk' x1='195.358' x2='177.219' y1='371.613' y2='367.148' className='hk'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='hl' x1='195.012' x2='206.695' y1='372.55' y2='363.488' className='hl'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='hm' x1='326.765' x2='315.793' y1='476.08' y2='478.494' className='hm'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#A97C50' />
              <stop offset='1' stopColor='#754C29' />
            </linearGradient>
            <linearGradient id='hn' x1='329.67' x2='338.297' y1='447.956' y2='462.896' className='hn'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='ho' x1='325.665' x2='347.264' y1='448.681' y2='451.174' className='ho'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='hp' x1='325.393' x2='311.288' y1='444.86' y2='465.187' className='hp'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='hq' x1='321.725' x2='302.5' y1='447.477' y2='459.465' className='hq'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='hr' x1='319.067' x2='299.489' y1='445.853' y2='452.995' className='hr'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='hs' x1='324.531' x2='339.767' y1='447.35' y2='439.9' className='hs'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='ht' x1='323.796' x2='311.795' y1='445.667' y2='433.222' className='ht'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='hu' x1='320.453' x2='306.477' y1='442.726' y2='441.695' className='hu'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='hv' x1='331.24' x2='323.714' y1='452.956' y2='441.766' className='hv'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='hw' x1='324.804' x2='325.101' y1='447.275' y2='463.68' className='hw'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='hx' x1='323.734' x2='342.885' y1='448.063' y2='443.699' className='hx'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='hy' x1='320.077' x2='293.267' y1='446.27' y2='439.673' className='hy'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='hz' x1='319.587' x2='336.858' y1='447.606' y2='434.219' className='hz'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='hA' x1='442.511' x2='443.909' y1='206.567' y2='211.537' className='hA'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='hB' x1='443.751' x2='444.417' y1='210.845' y2='213.246' className='hB'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='hC' x1='318.166' x2='319.134' y1='67.132' y2='68.17' className='hC'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='hD' x1='379.79' x2='380.759' y1='61.09' y2='62.128' className='hD'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='hE' x1='353.4' x2='354.369' y1='117.632' y2='118.67' className='hE'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='hF' x1='362.042' x2='363.034' y1='65.966' y2='67.692' className='hF'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='hG' x1='384.914' x2='385.907' y1='113.322' y2='115.039' className='hG'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='hH' x1='414.799' x2='414.642' y1='73.447' y2='131.632' className='hH'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='hI' x1='459.612' x2='468.059' y1='135.62' y2='165.774' className='hI'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='hJ' x1='413.268' x2='437.084' y1='68.786' y2='153.844' className='hJ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='hK' x1='419.623' x2='434.575' y1='93.972' y2='144.014' className='hK'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='hL' x1='467.237' x2='475.757' y1='132.33' y2='162.75' className='hL'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='hM' x1='467.3' x2='472.469' y1='132.529' y2='151.011' className='hM'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='hN' x1='390.52' x2='400.442' y1='82.675' y2='118.12' className='hN'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='hO' x1='458.337' x2='462.237' y1='91.047' y2='104.97' className='hO'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='hP' x1='390.7' x2='394.837' y1='112.304' y2='127.091' className='hP'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='hQ' x1='397.753' x2='397.596' y1='73.395' y2='131.581' className='hQ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='hR' x1='392.048' x2='391.882' y1='73.391' y2='131.572' className='hR'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='hS' x1='404.107' x2='403.949' y1='73.416' y2='131.601' className='hS'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='hT' x1='396.682' x2='396.521' y1='73.385' y2='131.579' className='hT'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='hU' x1='406.642' x2='406.484' y1='73.422' y2='131.607' className='hU'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='hV' x1='395.484' x2='395.327' y1='73.389' y2='131.575' className='hV'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='hW' x1='418.364' x2='418.197' y1='73.464' y2='131.645' className='hW'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='hX' x1='449.572' x2='458.02' y1='138.435' y2='168.589' className='hX'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='hY' x1='379.505' x2='403.321' y1='78.239' y2='163.297' className='hY'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='hZ' x1='385.813' x2='400.765' y1='104.067' y2='154.109' className='hZ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ia' x1='400.254' x2='400.096' y1='73.407' y2='131.592' className='ia'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ic' x1='394.467' x2='394.301' y1='73.395' y2='131.576' className='ic'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='id' x1='361.978' x2='397.26' y1='78.902' y2='78.902' className='id'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ie' x1='324.202' x2='345.613' y1='120.701' y2='120.701' className='ie'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='if' x1='341.395' x2='350.902' y1='60.425' y2='71.237' className='if'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ig' x1='340.234' x2='347.419' y1='97.227' y2='108.033' className='ig'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ih' x1='324.201' x2='345.603' y1='113.386' y2='113.386' className='ih'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ii' x1='332.379' x2='339.565' y1='102.461' y2='113.267' className='ii'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ij' x1='324.202' x2='345.603' y1='117.452' y2='117.452' className='ij'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ik' x1='326.658' x2='333.881' y1='106.185' y2='117.038' className='ik'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='il' x1='324.202' x2='345.603' y1='118.611' y2='118.611' className='il'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='im' x1='324.858' x2='332.053' y1='107.449' y2='118.254' className='im'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='in' x1='324.202' x2='345.603' y1='116.834' y2='116.834' className='in'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='io' x1='327.534' x2='334.728' y1='105.662' y2='116.478' className='io'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ip' x1='347.298' x2='369.938' y1='108.968' y2='134.676' className='ip'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='iq' x1='359.064' x2='369.074' y1='119.76' y2='131.134' className='iq'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ir' x1='320.384' x2='321.377' y1='90.489' y2='92.206' className='ir'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='is' x1='365.57' x2='366.564' y1='61.246' y2='62.963' className='is'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='it' x1='359.478' x2='359.853' y1='175.9' y2='176.228' className='it'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='iu' x1='359.806' x2='359.979' y1='176.178' y2='176.342' className='iu'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='iv' x1='350.382' x2='356.143' y1='154.747' y2='158.72' className='iv'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='iw' x1='350.401' x2='353.162' y1='167.942' y2='173.229' className='iw'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ix' x1='352.343' x2='354.915' y1='180.425' y2='185.341' className='ix'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='iy' x1='346.45' x2='347.001' y1='142.835' y2='143.349' className='iy'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='iz' x1='290.313' x2='291.441' y1='194.079' y2='194.332' className='iz'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='iA' x1='280.067' x2='281.195' y1='223.055' y2='223.309' className='iA'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='iB' x1='357.675' x2='357.979' y1='164.484' y2='164.77' className='iB'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='iC' x1='255.804' x2='257.342' y1='287.692' y2='288.215' className='iC'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='iD' x1='345.194' x2='345.726' y1='173.595' y2='174.099' className='iD'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='iE' x1='345.06' x2='354.457' y1='137.04' y2='137.04' className='iE'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='iF' x1='358.236' x2='361.429' y1='151.413' y2='154.146' className='iF'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='iG' x1='353.562' x2='353.477' y1='131.882' y2='133.848' className='iG'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='iH' x1='615.085' x2='607.323' y1='457.963' y2='459.676' className='iH'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#A97C50' />
              <stop offset='1' stopColor='#754C29' />
            </linearGradient>
            <linearGradient id='iI' x1='617.155' x2='623.267' y1='438.045' y2='448.623' className='iI'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='iJ' x1='614.311' x2='629.607' y1='438.56' y2='440.32' className='iJ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='iK' x1='614.127' x2='604.143' y1='435.87' y2='450.252' className='iK'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='iL' x1='611.52' x2='597.911' y1='437.698' y2='446.182' className='iL'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='iM' x1='609.647' x2='595.785' y1='436.56' y2='441.609' className='iM'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='iN' x1='613.512' x2='624.286' y1='437.615' y2='432.34' className='iN'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='iO' x1='613.018' x2='604.521' y1='436.417' y2='427.61' className='iO'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='iP' x1='610.631' x2='600.728' y1='434.355' y2='433.625' className='iP'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='iQ' x1='620.03' x2='614.705' y1='444.165' y2='436.248' className='iQ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='iR' x1='613.725' x2='613.936' y1='437.574' y2='449.191' className='iR'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='iS' x1='612.939' x2='626.508' y1='438.126' y2='435.043' className='iS'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='iT' x1='610.349' x2='591.376' y1='436.878' y2='432.207' className='iT'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='iU' x1='610.013' x2='622.231' y1='437.815' y2='428.335' className='iU'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#66A609' />
              <stop offset='.16' stopColor='#75B409' />
              <stop offset='.41' stopColor='#86C50A' />
              <stop offset='.69' stopColor='#91CF0A' />
              <stop offset='1' stopColor='#95D30B' />
            </linearGradient>
            <linearGradient id='iZ' x1='783.486' x2='1007.49' y1='634.413' y2='634.413' className='iZ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='1' stopColor='#ADBBC0' />
            </linearGradient>
            <linearGradient id='ja' x1='764.32' x2='831.489' y1='686.586' y2='686.586' className='ja'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='1' stopColor='#ADBBC0' />
            </linearGradient>
            <linearGradient id='jb' x1='795.002' x2='875.992' y1='733.031' y2='709.068' className='jb'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='1' stopColor='#ADBBC0' />
            </linearGradient>
            <linearGradient id='jc' x1='751.887' x2='831.489' y1='706.23' y2='706.23' className='jc'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='1' stopColor='#ADBBC0' />
            </linearGradient>
            <linearGradient id='jd' x1='730.517' x2='835.779' y1='726.01' y2='726.01' className='jd'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='1' stopColor='#ADBBC0' />
            </linearGradient>
            <linearGradient id='je' x1='740.391' x2='746.677' y1='767.568' y2='741.558' className='je'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.11' stopColor='#C3CDD1' />
              <stop offset='.29' stopColor='#B6C3C7' />
              <stop offset='.52' stopColor='#AFBCC1' />
              <stop offset='1' stopColor='#ADBBC0' />
            </linearGradient>
            <linearGradient id='jf' x1='756.292' x2='762.143' y1='773.797' y2='747.121' className='jf'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#BDC4C7' />
              <stop offset='.11' stopColor='#B0B9BD' />
              <stop offset='.29' stopColor='#A3AEB2' />
              <stop offset='.52' stopColor='#9CA7AC' />
              <stop offset='1' stopColor='#9AA6AB' />
            </linearGradient>
            <linearGradient id='jg' x1='767.063' x2='845.797' y1='774.116' y2='774.116' className='jg'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='1' stopColor='#ADBBC0' />
            </linearGradient>
            <linearGradient id='jh' x1='856.993' x2='876.237' y1='729.882' y2='729.882' className='jh'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='ji' x1='851.825' x2='876.247' y1='729.699' y2='729.699' className='ji'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jj' x1='862.113' x2='881.357' y1='730.23' y2='730.23' className='jj'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jk' x1='856.945' x2='881.367' y1='730.047' y2='730.047' className='jk'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jl' x1='867.233' x2='886.477' y1='730.587' y2='730.587' className='jl'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jm' x1='862.065' x2='886.487' y1='730.404' y2='730.404' className='jm'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jn' x1='872.354' x2='891.607' y1='730.935' y2='730.935' className='jn'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jo' x1='867.185' x2='891.607' y1='730.761' y2='730.761' className='jo'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jp' x1='877.483' x2='896.727' y1='731.292' y2='731.292' className='jp'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jq' x1='872.305' x2='896.737' y1='731.109' y2='731.109' className='jq'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jr' x1='882.603' x2='901.847' y1='731.64' y2='731.64' className='jr'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='js' x1='877.425' x2='901.857' y1='731.466' y2='731.466' className='js'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jt' x1='887.723' x2='906.967' y1='731.998' y2='731.998' className='jt'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='ju' x1='882.555' x2='906.977' y1='731.814' y2='731.814' className='ju'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jv' x1='892.844' x2='912.087' y1='732.355' y2='732.355' className='jv'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jw' x1='887.675' x2='912.097' y1='732.171' y2='732.171' className='jw'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jx' x1='897.964' x2='917.207' y1='732.703' y2='732.703' className='jx'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jy' x1='892.795' x2='917.217' y1='732.529' y2='732.529' className='jy'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jz' x1='903.084' x2='922.337' y1='733.06' y2='733.06' className='jz'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jA' x1='897.915' x2='922.337' y1='732.876' y2='732.876' className='jA'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jB' x1='908.213' x2='927.457' y1='733.408' y2='733.408' className='jB'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jC' x1='903.035' x2='927.467' y1='733.234' y2='733.234' className='jC'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jD' x1='913.333' x2='932.577' y1='733.765' y2='733.765' className='jD'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jE' x1='908.155' x2='932.587' y1='733.581' y2='733.581' className='jE'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jF' x1='918.453' x2='937.697' y1='734.113' y2='734.113' className='jF'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jG' x1='913.285' x2='937.707' y1='733.939' y2='733.939' className='jG'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jH' x1='923.574' x2='942.817' y1='734.47' y2='734.47' className='jH'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jI' x1='918.405' x2='942.827' y1='734.287' y2='734.287' className='jI'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jJ' x1='928.694' x2='947.937' y1='734.827' y2='734.827' className='jJ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jK' x1='923.525' x2='947.947' y1='734.644' y2='734.644' className='jK'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jL' x1='933.814' x2='953.067' y1='735.175' y2='735.175' className='jL'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jM' x1='928.645' x2='953.067' y1='735.001' y2='735.001' className='jM'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jN' x1='938.943' x2='958.187' y1='735.532' y2='735.532' className='jN'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jO' x1='933.766' x2='958.197' y1='735.349' y2='735.349' className='jO'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jP' x1='944.064' x2='963.307' y1='735.88' y2='735.88' className='jP'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jQ' x1='938.885' x2='963.317' y1='735.706' y2='735.706' className='jQ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jR' x1='949.184' x2='968.428' y1='736.237' y2='736.237' className='jR'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jS' x1='944.015' x2='968.437' y1='736.054' y2='736.054' className='jS'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jT' x1='954.304' x2='973.548' y1='736.595' y2='736.595' className='jT'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jU' x1='949.135' x2='973.557' y1='736.411' y2='736.411' className='jU'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jV' x1='959.424' x2='978.668' y1='736.942' y2='736.942' className='jV'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jW' x1='954.255' x2='978.677' y1='736.759' y2='736.759' className='jW'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jX' x1='964.544' x2='983.797' y1='737.3' y2='737.3' className='jX'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jY' x1='959.376' x2='983.797' y1='737.116' y2='737.116' className='jY'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='jZ' x1='969.674' x2='988.918' y1='737.647' y2='737.647' className='jZ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='ka' x1='965.471' x2='1019.23' y1='738.517' y2='733.492' className='ka'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kb' x1='964.544' x2='983.797' y1='737.3' y2='737.3' className='kb'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kc' x1='959.376' x2='983.797' y1='737.116' y2='737.116' className='kc'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kd' x1='959.424' x2='978.668' y1='736.942' y2='736.942' className='kd'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='ke' x1='954.255' x2='978.677' y1='736.759' y2='736.759' className='ke'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kf' x1='954.304' x2='973.548' y1='736.595' y2='736.595' className='kf'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kg' x1='949.135' x2='973.557' y1='736.411' y2='736.411' className='kg'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kh' x1='949.184' x2='968.428' y1='736.237' y2='736.237' className='kh'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='ki' x1='944.015' x2='968.437' y1='736.054' y2='736.054' className='ki'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kj' x1='944.064' x2='963.307' y1='735.88' y2='735.88' className='kj'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kk' x1='938.885' x2='963.317' y1='735.706' y2='735.706' className='kk'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kl' x1='938.943' x2='958.187' y1='735.532' y2='735.532' className='kl'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='km' x1='933.766' x2='958.197' y1='735.349' y2='735.349' className='km'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kn' x1='933.814' x2='953.067' y1='735.175' y2='735.175' className='kn'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='ko' x1='928.645' x2='953.067' y1='735.001' y2='735.001' className='ko'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kp' x1='928.694' x2='947.937' y1='734.827' y2='734.827' className='kp'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kq' x1='923.525' x2='947.947' y1='734.644' y2='734.644' className='kq'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kr' x1='923.574' x2='942.817' y1='734.47' y2='734.47' className='kr'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='ks' x1='918.405' x2='942.827' y1='734.287' y2='734.287' className='ks'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kt' x1='918.453' x2='937.697' y1='734.113' y2='734.113' className='kt'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='ku' x1='913.285' x2='937.707' y1='733.939' y2='733.939' className='ku'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kv' x1='913.333' x2='932.577' y1='733.765' y2='733.765' className='kv'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kw' x1='908.155' x2='932.587' y1='733.581' y2='733.581' className='kw'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kx' x1='908.213' x2='927.457' y1='733.408' y2='733.408' className='kx'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='ky' x1='903.035' x2='927.467' y1='733.234' y2='733.234' className='ky'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kz' x1='903.084' x2='922.337' y1='733.06' y2='733.06' className='kz'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kA' x1='897.915' x2='922.337' y1='732.876' y2='732.876' className='kA'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kB' x1='897.964' x2='917.207' y1='732.703' y2='732.703' className='kB'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kC' x1='892.795' x2='917.217' y1='732.529' y2='732.529' className='kC'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kD' x1='892.844' x2='912.087' y1='732.355' y2='732.355' className='kD'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kE' x1='887.675' x2='912.097' y1='732.171' y2='732.171' className='kE'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kF' x1='887.723' x2='906.967' y1='731.998' y2='731.998' className='kF'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kG' x1='882.555' x2='906.977' y1='731.814' y2='731.814' className='kG'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kH' x1='882.603' x2='901.847' y1='731.64' y2='731.64' className='kH'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kI' x1='877.425' x2='901.857' y1='731.466' y2='731.466' className='kI'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kJ' x1='877.483' x2='896.727' y1='731.292' y2='731.292' className='kJ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kK' x1='872.305' x2='896.737' y1='731.109' y2='731.109' className='kK'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kL' x1='872.354' x2='891.607' y1='730.935' y2='730.935' className='kL'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kM' x1='867.185' x2='891.607' y1='730.761' y2='730.761' className='kM'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kN' x1='867.233' x2='886.477' y1='730.587' y2='730.587' className='kN'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kO' x1='862.065' x2='886.487' y1='730.404' y2='730.404' className='kO'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kP' x1='862.113' x2='881.357' y1='730.23' y2='730.23' className='kP'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kQ' x1='856.945' x2='881.367' y1='730.047' y2='730.047' className='kQ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kR' x1='856.993' x2='876.237' y1='729.882' y2='729.882' className='kR'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='kS' x1='797.204' x2='884.348' y1='749.469' y2='723.679' className='kS'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='1' stopColor='#ADBBC0' />
            </linearGradient>
            <linearGradient id='kT' x1='807.135' x2='938.746' y1='820.977' y2='726.943' className='kT'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='1' stopColor='#ADBBC0' />
            </linearGradient>
            <linearGradient id='kU' x1='797.513' x2='884.532' y1='748.349' y2='722.598' className='kU'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='1' stopColor='#ADBBC0' />
            </linearGradient>
            <linearGradient id='kV' x1='982.194' x2='1025.58' y1='685.842' y2='685.842' className='kV'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='1' stopColor='#ADBBC0' />
            </linearGradient>
            <linearGradient id='kW' x1='975.625' x2='1027.97' y1='680.752' y2='693.121' className='kW'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#ADBBC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='kX' x1='996.598' x2='1013.02' y1='666.584' y2='672.063' className='kX'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#C2C9CC' />
              <stop offset='1' stopColor='#9FABB0' />
            </linearGradient>
            <linearGradient id='kY' x1='1002.28' x2='1019.41' y1='673.393' y2='667.875' className='kY'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#ADBBC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='kZ' x1='973.548' x2='996.086' y1='666.313' y2='666.313' className='kZ'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='1' stopColor='#ADBBC0' />
            </linearGradient>
            <linearGradient id='la' x1='973.548' x2='996.086' y1='666.313' y2='666.313' className='la'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#ADBBC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='lb' x1='728.875' x2='736.362' y1='745.837' y2='745.837' className='lb'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#BDC4C7' />
              <stop offset='.11' stopColor='#B0B9BD' />
              <stop offset='.29' stopColor='#A3AEB2' />
              <stop offset='.52' stopColor='#9CA7AC' />
              <stop offset='1' stopColor='#9AA6AB' />
            </linearGradient>
            <linearGradient id='lc' x1='927.467' x2='962.834' y1='686.798' y2='686.798' className='lc'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='1' stopColor='#ADBBC0' />
            </linearGradient>
            <linearGradient id='ld' x1='927.467' x2='962.834' y1='686.798' y2='686.798' className='ld'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#ADBBC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='le' x1='965.307' x2='1018.46' y1='737.928' y2='732.961' className='le'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#D1D9DC' />
              <stop offset='.63' stopColor='#B0BCBF' />
              <stop offset='1' stopColor='#A3B1B3' />
            </linearGradient>
            <linearGradient id='lf' x1='869.281' x2='880.135' y1='472.603' y2='495.263' className='lf'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='lg' x1='925.438' x2='941.914' y1='483.372' y2='502.054' className='lg'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='lh' x1='879.039' x2='904.187' y1='470.246' y2='498.754' className='lh'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='li' x1='886.265' x2='901.418' y1='478.794' y2='495.4' className='li'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='lj' x1='938.335' x2='954.936' y1='472.168' y2='490.977' className='lj'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='lk' x1='938.441' x2='948.524' y1='472.294' y2='483.722' className='lk'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ll' x1='846.009' x2='855.416' y1='473.994' y2='484.649' className='ll'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='lm' x1='912.097' x2='916.839' y1='468.18' y2='473.56' className='lm'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ln' x1='935.891' x2='943.057' y1='502.91' y2='511.034' className='ln'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='lo' x1='932.321' x2='933.535' y1='510.533' y2='511.91' className='lo'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='lp' x1='933.37' x2='933.948' y1='511.718' y2='512.383' className='lp'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='lq' x1='849.284' x2='854.113' y1='505.749' y2='511.227' className='lq'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='lr' x1='862.992' x2='864.2' y1='470.797' y2='472.082' className='lr'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='ls' x1='908.619' x2='909.479' y1='505.218' y2='506.136' className='ls'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='lt' x1='927.641' x2='929.331' y1='466.654' y2='468.45' className='lt'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='lu' x1='872.327' x2='873.565' y1='508.691' y2='510.824' className='lu'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='lv' x1='904.243' x2='904.969' y1='605.561' y2='606.19' className='lv'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='lw' x1='904.863' x2='905.217' y1='606.106' y2='606.402' className='lw'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#FFEFC0' />
              <stop offset='1' stopColor='white' />
            </linearGradient>
            <linearGradient id='lx' x1='890.332' x2='903.294' y1='560.133' y2='569.051' className='lx'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='ly' x1='890.689' x2='898.028' y1='587.369' y2='601.413' className='ly'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='lz' x1='894.36' x2='899.604' y1='613.059' y2='623.085' className='lz'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='lA' x1='887.501' x2='888.737' y1='535.728' y2='536.887' className='lA'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='lB' x1='818.106' x2='819.143' y1='594.218' y2='594.451' className='lB'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='lC' x1='804.564' x2='805.597' y1='655.521' y2='655.753' className='lC'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='lD' x1='902.108' x2='902.697' y1='581.458' y2='582.019' className='lD'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='lE' x1='777.549' x2='778.587' y1='746.005' y2='746.358' className='lE'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='lF' x1='886.216' x2='886.825' y1='600.919' y2='601.499' className='lF'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <linearGradient id='lG' x1='886.004' x2='898.06' y1='523.501' y2='523.501' className='lG'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='lH' x1='902.968' x2='909.516' y1='552.987' y2='558.571' className='lH'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#B8A878' />
              <stop offset='.98' stopColor='#E2CE92' />
            </linearGradient>
            <linearGradient id='lI' x1='897.007' x2='896.853' y1='512.655' y2='516.643' className='lI'
                            gradientUnits='userSpaceOnUse'>
              <stop stopColor='#746D46' />
              <stop offset='1' stopColor='#BFB07C' />
            </linearGradient>
            <clipPath id='a' className='a'>
              <path fill='white' d='M500 5h575v383H500z' />
            </clipPath>
            <clipPath id='iY' className='iY'>
              <path fill='white' d='M581 458h559v414H581z' />
            </clipPath>
            <clipPath id='lJ' className='lJ'>
              <path fill='white' d='M851 474h98v30h-98z' />
            </clipPath>
          </defs>
        </svg>
      }
    </Container>
  );
}
