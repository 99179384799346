import { TGameFillInGame } from './types';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The method is converting the server side object into the "fill in game"
 *    game type.
 * @param { Record<string, any> } dbData
 *    The server side data in json format.
 */
export function fillInGameDbToViewConverter(
  dbData: Record<string, any>
): TGameFillInGame {
  return {
    text: dbData.text,
    answers: dbData.answers,
    filePath: dbData.filePath ?? null,
    audio: dbData.audio ?? null,
  }
}
