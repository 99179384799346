import { ReactNode } from 'react';
import { motion } from 'framer-motion';
import Button from 'react-bootstrap/Button';
import { useMemo } from 'react';
import { ButtonVariant } from 'react-bootstrap/types';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description - The input props for the button
 */
interface ButtonMotionProps {
  children: ReactNode | ReactNode[];
  variant: ButtonVariant;
  onClick: VoidFunction;
  className?: string;
  disabled?: boolean;
}

/**
 * @description - The custom button component
 * @param children
 * @param variant
 * @param clickHandler
 * @param className
 * @constructor
 */
export default function ButtonMotion(
  {
    children,
    variant,
    onClick,
    className,
    disabled
  }: ButtonMotionProps
) {
  const Btn = useMemo(() => motion(Button), []);
  return (
    <Btn
      whileTap={{ scale: 0.97 }}
      variant={variant}
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Btn>
  );
}
