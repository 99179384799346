import { memo } from 'react';
import ToggleButton from '../toggle-button';
import { motion, useCycle } from 'framer-motion';
import MenuToggle from './menu-toggle';
import MenuContent from './menu-content';
import { checkIsMobile } from '../../helpers';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

interface MainSidebarProps {
  isMenuOpen: boolean;
  onMenuToggle: VoidFunction;
}

const sidebar = {
  open: (height = 1500) => ({
    height:'100vh',
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2
    }
  }),
  closed: {
    height:'70px',
    clipPath: 'circle(30px at 40px 40px)',
    transition: {
      delay: 0.2,
      type: 'spring',
      stiffness: 400,
      damping: 40
    }
  }
};

function MainSidebar({ isMenuOpen, onMenuToggle }: MainSidebarProps) {
  const isMobile = checkIsMobile();
  const [isOpen, toggleOpen] = useCycle(false, true);

  return !isMobile ?
    <div className={`position-fixed left-0 top-0 text-white sidebar flex-shrink-0 ${isMenuOpen ? 'open' : ''}`}>
      <MenuContent isMobile={isMobile} isOpen={isMenuOpen} />
      <ToggleButton onToggle={onMenuToggle} />
    </div>
    :
    <div className='sidebar-mobile position-fixed top-0 left-0'>
      <motion.nav
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        className={isOpen ? '' : 'closed'}
      >
        <motion.div className='background' variants={sidebar} />
        <MenuContent isMobile={isMobile} isOpen={isOpen} />
        <MenuToggle toggle={toggleOpen} />
      </motion.nav>
    </div>;
}

export default memo(MainSidebar);
