import ImageHolderPortrait from '../../assets/svg/image-holder-portrait';
import ImageHolderLandscape from '../../assets/svg/image-holder-landscape';
import UploadBtn from '../../assets/svg/buttons/upload-btn';
import React, { memo, useState } from 'react';
import { TargetBox } from './target-box';
import { uploadFile } from '../../api/v0';
import { Loading } from '../loader';


interface UploaderProps {
  currentUrl?: string | null;
  position?: string,
  gameId?: string | null,
  onGameMechanicContentChange: (filePath: string) => void;
}

function Uploader({ position, currentUrl, gameId, onGameMechanicContentChange }: UploaderProps) {
  const [uploadedImg, setUploadedImg] = useState<string | undefined>(currentUrl?.includes('image') ? currentUrl : undefined);
  const [uploadedVideo, setUploadedVideo] = useState<string | undefined>(currentUrl?.includes('video') ? currentUrl : undefined);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileUpload = (type: 'image' | 'video', file: File) => {
    if (gameId) {
      setIsLoading(true);
      uploadFile(
        `${gameId}/${type}/${file.name}`,
        file,
        (url: string) => {
          onGameMechanicContentChange(url);
          setIsLoading(false);
        },
        (error: string) => {
          console.log(error);
          setIsLoading(false);
        }
      );
    }
  };

  function saveByType(file: File) {
    if (file.type.includes('video')) {
      handleFileUpload('video', file);
      setUploadedVideo(URL.createObjectURL(file));
    } else {
      handleFileUpload('image', file);
      setUploadedImg(URL.createObjectURL(file));
    }
  }

  const onUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files?.length) {
      let file = files[0];
      saveByType(file);
    }
  };

  const handleFileDrop = (item: { files: File[] }) => {
    if (item) {
      const file = item.files[0];
      saveByType(file);
    }
  };

  const getTargetView = () => {

    return;
  };

  return (
    <TargetBox onDrop={handleFileDrop}>
      <div className={`uploader position-relative`}>
        {isLoading && <Loading />}
        {uploadedImg
          ? (
            <div
              className='uploaded-img d-flex justify-content-center'
            >
              <img src={uploadedImg} alt='uploaded' style={{ width: '100%' }}/>
              <label>
                <input type='file' onChange={onUpload}
                       accept='image/png, image/gif, image/jpeg, image/svg, video/mp4' />
                <UploadBtn />
              </label>
            </div>
          ) : uploadedVideo ? <div className='uploaded-img'>
              <video className='img-fluid' controls>
                <source src={uploadedVideo} type='video/mp4' />
              </video>
              <label>
                <input type='file' onChange={onUpload}
                       accept='image/png, image/gif, image/jpeg, image/svg, video/mp4' />
                <UploadBtn />
              </label>
            </div> :
            <label>
              {position === 'landscape' ?
                <ImageHolderLandscape /> : <ImageHolderPortrait />}
              <input type='file' onChange={onUpload}
                     accept='image/png, image/gif, image/jpeg, image/svg, video/mp4' />
              <UploadBtn />
            </label>
        }
      </div>
    </TargetBox>
  );
}

export default memo(Uploader);