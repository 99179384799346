import { memo, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { getPlayedGames } from "../../api/v0";
import { useAuth } from "../../hooks";

const data = [{ user:'Test.Researcher@mcast.edu.mt', name: 8, points: 275, updated: '19.09.2023 14:23' }];

function Analytics() {
  const [playedGames, setPlayedGames] = useState<Record<string, any>>({});
  useEffect(() => {
    getPlayedGames(setPlayedGames);
  }, []);

  const { user } = useAuth();
  const role = user?.role?.toLowerCase();

  if (role !== 'admin' && role !== 'mentor' && role !== 'teacher' && role !== 'lecturer' && role !== 'administrator') {
    return <p>Permission Denied</p>;
  }

  return (
    <div className='game-wrapper animate'>
      <div className='game-container editor main-screen'>
        <Container>
          <table className='table text-capitalize'>
            <thead className='thead-light'>
            <tr>
              <th>user</th>
              <th>games played</th>
              <th>points</th>
              <th>last visit</th>
            </tr>
            </thead>
            <tbody>
            {Object.values(playedGames).map((row, i) => {
              return (
                <tr key={`row-${i}`}>
                  <td>{row.user}</td>
                  <td>{row.numberGames}</td>
                  <td>{row.points}</td>
                  <td>{row.updated}</td>
                </tr>);
            })}
            </tbody>
          </table>
        </Container>
      </div>
    </div>
  );
}

export default memo(Analytics);