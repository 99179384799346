import FilledStar from 'src/assets/svg/games/filled-star';
import EmptyStar from 'src/assets/svg/games/empty-star';

export default function LevelInfo({level} : {level:number}){
  return (
    <div className='level-info'><h3>Level {level}</h3>
      <div className='level-stars'>{new Array(3).fill('').map((el, i) => (
        i < level ? <FilledStar key={Math.random()} /> :
          <EmptyStar key={Math.random()} />))}</div>
    </div>
  )
}