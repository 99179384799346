import { useGame } from 'src/hooks';
import {
  EnumGameLevel,
  EnumGameSkillsCategory,
  EnumGameType,
  NewGameEmptyData
} from 'src/@constants';
import { Col, Container, Row } from 'react-bootstrap';
import 'src/assets/styles/main-screen.scss';
import React, { useState } from 'react';
import { TGame } from 'src/@types';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonMotion } from 'src/components';
import EditorCloseBtn from '../../assets/svg/buttons/editor-close-btn';
import { CLASSTER_TENANTS_BY_UUID, PROGRAM_NAMES_BY_UUID } from '../../@constants/tenants-constants';

/**
 * @description
 *    The game creation component that allowing to create the empty game data in
 *    order to start populating it
 */
export function IslandGameCreate() {
  const { createGame } = useGame();
  // Navigation methods
  const navigate = useNavigate();
  const { island: islandId } = useParams();

  /**
   * @description
   *    Parsing the initial state for the new game with the provided mini world
   */
  const miniWorldId = islandId || 'na';
  const [currentGameData, setCurrentGameData] = useState<TGame>({
    ...NewGameEmptyData,
    mini_world_id: miniWorldId
  });

  /**
   * @description
   *    The method is updating the input data for the initial game info.
   * @param {string} inputType
   *    The content type which value should be changed in the internal component
   * @param value
   *    The final value of the selected input
   */
  const handleOnGameContentChange = (
    inputType: 'title' | 'description' | 'level' | 'skill' | 'type' |'tenant_id' | 'program_name',
    value: string
  ) => {
    setCurrentGameData({
      ...currentGameData,
      [inputType]: value
    });
  };

  return (
    <Container className='islands'>
      <div className='game-wrapper animate'>
        <div className='game-container editor main-screen'>
          {/* HEADER SECTION OF THE GAME PLAYABLE VIEW */}
          <div className='game-info'>
            <input
              placeholder='Game Title'
              value={currentGameData.title}
              onChange={(e) => handleOnGameContentChange('title', e.target.value)}
            />
            <EditorCloseBtn close={() => navigate(-1)} />
            <textarea
              placeholder='Game Description'
              value={currentGameData.description}
              onChange={(e) => handleOnGameContentChange('description', e.target.value)}
              rows={8}
            />
            <Row>
              <Col md={3}>
                <h3>Select key skill</h3>
                <div className='options'>
                  {Object.keys(EnumGameSkillsCategory).map((opt, i) => {
                    return (
                      <p key={`opt-${i}`} className='custom-radio'>
                        <input
                          type='radio'
                          checked={currentGameData.skill === opt}
                          id={`opt-${i}`}
                          name='skill'
                          onChange={() => handleOnGameContentChange('skill', opt)}
                        />
                        <label htmlFor={`opt-${i}`}>{opt}</label>

                      </p>);
                  })}
                </div>
              </Col>
              <Col md={3}>
                <h3>Select level</h3>
                <div className='options'>
                  {Object.keys(EnumGameLevel).map((opt, i) => {
                    return (
                      <p key={`opt-${i}`} className='custom-radio'>
                        <input
                          type='radio'
                          checked={currentGameData.level === opt}
                          id={`level-${i}`}
                          name='level'
                          onChange={() => handleOnGameContentChange('level', opt)}
                        />
                        <label htmlFor={`level-${i}`}>{i + 1}</label>
                      </p>
                    );
                  })}
                </div>
              </Col>
              <Col md={3}>
                <h3>Select game type</h3>
                <div className='options'>
                  {Object.keys(EnumGameType).map((opt, i) => {
                    return (
                      <p key={`opt-${i}`} className='custom-radio'>
                        <input
                          type='radio'
                          checked={currentGameData.type === opt}
                          id={`type-${i}`}
                          name='type'
                          onChange={() => handleOnGameContentChange('type', opt)}
                        />
                        <label htmlFor={`type-${i}`}>{opt} game</label>
                      </p>
                    );
                  })}
                </div>
              </Col>
              <Col md={3}>
                <div className='mb-3'>
                  <select
                    className="form-control"
                    defaultValue={currentGameData.tenant_id ?? undefined}
                    onChange={(e) => handleOnGameContentChange('tenant_id',e.target.value)}
                  >
                    <option key={'none'} value={''}>Institute</option>
                    {Object
                      .entries(CLASSTER_TENANTS_BY_UUID)
                      .map(([tenantId, tenantName]) =>
                        <option value={tenantId} key={tenantId}>{tenantName}</option>)
                    }
                  </select>
                </div>
                <div>
                  <select
                    className="form-control"
                    defaultValue={currentGameData.program_name ?? undefined}
                    onChange={(e) => handleOnGameContentChange('program_name',e.target.value)}
                  >
                    <option key={'none'} value={''}>Program</option>
                    {Object
                      .entries(PROGRAM_NAMES_BY_UUID)
                      .map(([instituteId, programmItems]) => {
                        if (currentGameData?.tenant_id && instituteId === currentGameData.tenant_id) {
                          return Object.values(programmItems).map((programName) =>
                            <option
                              value={programName}
                              key={programName}>
                              {programName}
                            </option>
                          )
                        }
                      })
                    }
                  </select>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className='submit-btn'>
          <ButtonMotion
            className='btn-block'
            variant={'success'}
            disabled={!(
              typeof currentGameData.skill === 'string' &&
              typeof currentGameData.level === 'string' &&
              typeof currentGameData.type === 'string'
            )}
            onClick={() => createGame(currentGameData)}
          >
            Create and continue
          </ButtonMotion>
        </div>
      </div>
    </Container>
  );
}
