import { Editor } from '@tinymce/tinymce-react';
import React, { FC } from 'react';
import '@wiris/mathtype-tinymce5'


interface IEditor {
  handleChange: (value: string) => void;
  handleSelect?: (event: any) => void;
  value?: string,
  maxHeight?: number

}

const TinyMceEditor: FC<IEditor> = ({ handleChange, handleSelect, value, maxHeight }) => {
  return (
    <Editor
      apiKey={process.env.REACT_APP_TINY_MCE_KEY}
      init={{
        menubar: false,
        max_height: maxHeight,
        plugins: 'textcolor',
        external_plugins: {
          'tiny_mce_wiris': `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`
        },
        toolbar:
          'fontsize | forecolor | undo redo | formatselect | ' +
          'superscript subscript |' +
          'bold italic | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat  | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry'
      }}
      value={value}
      onSelectionChange={handleSelect}
      onEditorChange={(newValue, editor) => {
        handleChange(newValue);
      }}
    />
  )
}

export default TinyMceEditor;